import {secondsToTimeStringShort} from "@/js/store/common_functions/functions";
import {FrontEndWidgetDefinition, TableData} from "@/js/store/types";

export type BreakPoint = "2xl" | "xl" | "lg" | "md" | "sm";

export type BreakPointType = {
  name: BreakPoint;
  bp: number;
  cols: number;
};

const breakPointsDetails = (): Record<BreakPoint, BreakPointType> => ({
  "2xl": {
    name: "2xl",
    bp: 1536,
    cols: 12,
  },
  xl: {
    name: "xl",
    bp: 1280,
    cols: 10,
  },
  lg: {
    name: "lg",
    bp: 1024,
    cols: 8,
  },
  md: {
    name: "md",
    bp: 768,
    cols: 6,
  },
  sm: {
    name: "sm",
    bp: 640,
    cols: 3,
  },
});

const breakPointsObj = (): Record<BreakPoint, number> => ({
  "2xl": 1536,
  xl: 1280,
  lg: 1024,
  md: 768,
  sm: 640,
});

const breakPointCols = (): Record<BreakPoint, number> => ({
  "2xl": 12,
  xl: 10,
  lg: 8,
  md: 6,
  sm: 3,
});

const emptyLayout = (): Record<BreakPoint, Array<FrontEndWidgetDefinition>> => ({
  "2xl": [],
  xl: [],
  lg: [],
  md: [],
  sm: [],
});

const defaultTableData = (): TableData => ({
  columns: [],
  data: [],
  default_sort: ["", "desc"],
  default_sort_direction: "desc",
  per_page: 15,
  total: 0,
  page: 1,
  loading: true,
});

const defaultPaginator = (): LaravelLengthAwarePaginator<any> => ({
  current_page: 1,
  data: [],
  first_page_url: "",
  from: 0,
  last_page: 1,
  last_page_url: "",
  links: [],
  next_page_url: "",
  path: "",
  per_page: 15,
  prev_page_url: "",
  to: 0,
  total: 0,
});

const defaultHourOfDay = (): App.DataObjects.ReportResponses.Stats.HourOfDay => ({
  "00:00": 0,
  "01:00": 0,
  "02:00": 0,
  "03:00": 0,
  "04:00": 0,
  "05:00": 0,
  "06:00": 0,
  "07:00": 0,
  "08:00": 0,
  "09:00": 0,
  "10:00": 0,
  "11:00": 0,
  "12:00": 0,
  "13:00": 0,
  "14:00": 0,
  "15:00": 0,
  "16:00": 0,
  "17:00": 0,
  "18:00": 0,
  "19:00": 0,
  "20:00": 0,
  "21:00": 0,
  "22:00": 0,
  "23:00": 0,
});

const defaultDayOfWeek = (): App.DataObjects.ReportResponses.Stats.DayOfWeek => ({
  Monday: 0,
  Tuesday: 0,
  Wednesday: 0,
  Thursday: 0,
  Friday: 0,
  Saturday: 0,
  Sunday: 0,
});

const myCompany = (): App.DataObjects.ElasticsearchEntityObject => ({
  name: "My Company",
  model_type: "Internal",
  icon: "building",
  id: null,
  value: "My Company",
  email_usernames: null,
});
const myDomains = (): App.DataObjects.ElasticsearchEntityObject => ({
  name: "My Domains",
  model_type: "Internal",
  icon: "folder-tree",
  id: null,
  value: "My Domains",
  email_usernames: null,
});
const anybody = (): App.DataObjects.ElasticsearchEntityObject => ({
  name: "Anybody",
  model_type: "Anybody",
  icon: "globe-americas",
  id: null,
  value: "Anybody",
  email_usernames: null,
});
const everybodyElse = (): App.DataObjects.ElasticsearchEntityObject => ({
  name: "Everybody Else",
  model_type: "External",
  icon: "globe",
  id: null,
  value: "Everybody Else",
  email_usernames: null,
});
const allLeads = (): App.DataObjects.ElasticsearchEntityObject => ({
  name: "All Leads",
  model_type: "Anybody",
  icon: "globe-americas",
  id: null,
  value: "All Leads",
  email_usernames: null,
});

const overview = (): {
  direction: "asc" | "desc";
  sortBy: string;
  perPageAgents: number;
  pageAgents: number;
  perPageDomains: number;
  pageDomains: number;
  perPageContacts: number;
  pageContacts: number;
} => ({
  direction: "desc",
  sortBy: "threads.total",
  perPageAgents: 15,
  pageAgents: 1,
  perPageDomains: 15,
  pageDomains: 1,
  perPageContacts: 15,
  pageContacts: 1,
});

const productivity = (): {
  email_volumes: {
    direction: "asc" | "desc";
    sortBy: string;
  };
  conversations: {
    direction: "asc" | "desc";
    sortBy: string;
  };
  average_reply_times: {
    direction: "asc" | "desc";
    sortBy: string;
  };
  responsiveness: {
    direction: "asc" | "desc";
    sortBy: string;
  };
  activity: {
    direction: "asc" | "desc";
    sortBy: string;
  };
  perPage: number;
  page: number;
} => ({
  email_volumes: {
    direction: "desc",
    sortBy: "messages_sent_count",
  },
  conversations: {
    direction: "desc",
    sortBy: "threads_count",
  },
  average_reply_times: {
    direction: "asc",
    sortBy: "initialTTR",
  },
  responsiveness: {
    direction: "desc",
    sortBy: "replies_under_7200",
  },
  activity: {
    direction: "asc",
    sortBy: "first_activity",
  },
  perPage: 15,
  page: 1,
});

const comparative = (): {
  direction: "asc" | "desc";
  perPage: number;
  page: number;
} => ({
  direction: "desc",
  perPage: 15,
  page: 1,
});

const leadSourcesReport = (): {
  direction: "asc" | "desc";
  perPage: number;
  page: number;
} => ({
  direction: "desc",
  perPage: 15,
  page: 1,
});

const salesRepReport = (): {
  direction: "asc" | "desc";
  sortBy: string;
  perPage: number;
  page: number;
} => ({
  direction: "desc",
  sortBy: "name",
  perPage: 15,
  page: 1,
});

const customer = (): {
  directionEmails: "asc" | "desc";
  sortByEmails: string;
  perPageEmails: number;
  pageEmails: number;
  directionDomains: "asc" | "desc";
  sortByDomains: string;
  perPageDomains: number;
  pageDomains: number;
} => ({
  directionEmails: "desc",
  sortByEmails: "threads.total",
  perPageEmails: 15,
  pageEmails: 1,
  directionDomains: "desc",
  sortByDomains: "threads.total",
  perPageDomains: 15,
  pageDomains: 1,
});

const sla = (goals: {
  first_reply_time_goal: number;
  overall_reply_time_goal: number;
  time_to_close_goal: number;
}): {
  direction: "asc" | "desc";
  sortBy: string;
  perPageAgents: number;
  pageAgents: number;
  perPageDomains: number;
  pageDomains: number;
  perPageContacts: number;
  pageContacts: number;
  init_ttr_goal: string;
  overall_ttr_goal: string;
  overall_ttc_goal: string;
} => ({
  direction: "desc",
  sortBy: "threads.total",
  perPageAgents: 15,
  pageAgents: 1,
  perPageDomains: 15,
  pageDomains: 1,
  perPageContacts: 15,
  pageContacts: 1,
  init_ttr_goal: goals ? secondsToTimeStringShort(goals.first_reply_time_goal) : "02:00:00",
  overall_ttr_goal: goals ? secondsToTimeStringShort(goals.overall_reply_time_goal) : "02:00:00",
  overall_ttc_goal: goals ? secondsToTimeStringShort(goals.time_to_close_goal) : "02:00:00",
});

const trend = (): {
  perPage: number;
  page: number;
  showAgents: boolean;
} => ({
  perPage: 10,
  page: 1,
  showAgents: true,
});

const mailLogs = (): {
  direction: "asc" | "desc";
  sortBy: string;
  perPage: number;
  page: number;
  unfilteredView: boolean;
} => ({
  direction: "desc",
  sortBy: "last_received_at_date_time",
  perPage: 15,
  page: 1,
  unfilteredView: false,
});

const messageLogs = (): {
  direction: "asc" | "desc";
  sortBy: string;
  perPage: number;
  page: number;
  unfilteredView: boolean;
} => ({
  direction: "desc",
  sortBy: "date_time",
  perPage: 15,
  page: 1,
  unfilteredView: false,
});

const threadType = (): App.Enums.ThreadType[] => ["inbound", "outbound", "internal"];
const threadStatus = (): App.Enums.ThreadStatus[] => ["internal", "await-customer", "closed", "await-agent"];

const waitingForResponse = () => ["customer-has-response", "waiting-for-response"];
const hasReplies = () => ["hasReplies", "hasForwards", "hasNoRepliesOrForwards"];
const classification = (): App.Enums.MessageClassification[] => ["first", "reply", "reply-all", "forward", "follow-up"];
const messageType = (): App.Enums.MessageType[] => ["inbound", "outbound", "internal"];

export {
  allLeads,
  anybody,
  breakPointCols,
  breakPointsDetails,
  breakPointsObj,
  classification,
  comparative,
  customer,
  defaultDayOfWeek,
  defaultHourOfDay,
  defaultPaginator,
  defaultTableData,
  emptyLayout,
  everybodyElse,
  hasReplies,
  leadSourcesReport,
  mailLogs,
  messageLogs,
  messageType,
  myCompany,
  myDomains,
  overview,
  productivity,
  salesRepReport,
  sla,
  threadStatus,
  threadType,
  trend,
  waitingForResponse,
};
