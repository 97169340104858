import {apiCompanyEntitiesSearch, apiMessageFiltersAll} from "@/js/http/api";
import {
  allLeads,
  anybody,
  classification,
  comparative,
  customer,
  everybodyElse,
  hasReplies,
  leadSourcesReport,
  mailLogs,
  messageLogs,
  messageType,
  myCompany,
  myDomains,
  overview,
  productivity,
  salesRepReport,
  sla,
  threadStatus,
  threadType,
  trend,
  waitingForResponse,
} from "@/js/misc/defaultObjects";
import {convertStringToDate, getDateLocalized, isLaravelLengthAwarePaginator, secondsToTimeStringShort, startOfDayYMDFormat, todayMinusDays} from "@/js/store/common_functions/functions";
import {RootGetters, RootState, SearchParams} from "@/js/store/types";
import {AxiosError} from "axios";
import {cloneDeep, debounce, difference, get, has, isArray} from "lodash-es";
import {ActionContext, ActionTree, GetterTree, Module, MutationTree} from "vuex";

let filtersCancelController: AbortController | undefined = undefined;
let entitiesSearchCancelController: AbortController | undefined = undefined;

export type ThreadFiltersState = {
  minimal: boolean;
  productType: App.Enums.ProductType | null;
  isTrend: boolean;
  fixedThreadType: App.Enums.ThreadType | "";
  fixedModelTypes: string[];
  fixedModelTypesCom: string[];
  dates: Date[];
  date: Date;
  periods: number;
  periodType: string;
  modelSelectedFull: App.DataObjects.ElasticsearchEntityObject;
  excludeCc: boolean;
  modelSelectedFullCom: App.DataObjects.ElasticsearchEntityObject;
  excludeCcCom: boolean;
  exclusively: boolean;
  search: string;
  modelSearch: string;
  modelComSearch: string;
  label: string[];
  labelsAndOr: string;
  dealStage: number | null;
  touches: string;
  lastTouchDate: string | null;
  nudges: string;
  agent: any;
  dealOwner: App.DataObjects.ElasticsearchEntityObject | null;
  dealName: string;
  dealValueFrom: number | null;
  dealValueTo: number | null;
  repliesOver: number;
  closedOver: number;
  messageRepliesOver: number;
  noReplyFor: number;
  threadType: App.Enums.ThreadType[];
  threadStatus: App.Enums.ThreadStatus[];
  waitingForResponse: string[];
  hasReplies: string[];
  classification: string[];
  messageType: string[];
  goals: {
    first_reply_time_goal: number;
    overall_reply_time_goal: number;
    time_to_close_goal: number;
  };
  agents: any[];
  agentsCom: any[];
  leadSources: any[];
  leadSourcesCom: any[];
  teams: any[];
  teamsCom: any[];
  groupMailboxes: any[];
  groupMailboxesCom: any[];
  customerGroups: any[];
  customerGroupsCom: any[];
  customers: any[];
  customersCom: any[];
  otherEntities: any[];
  isLoading: boolean;
  isSearching: boolean;
  initialFilterDataSet: boolean;
  defaultSortDirection: string;
  advancedFilters: {
    active: number;
    total: number;
  };
  emailAndDomainFilters: {
    domains: number;
    emails: number;
  };
  leadSourcesReport: {
    direction: "asc" | "desc";
    perPage: number;
    page: number;
  };
  salesRepReport: {
    direction: "asc" | "desc";
    sortBy: string;
    perPage: number;
    page: number;
  };
  overview: {
    direction: "asc" | "desc";
    sortBy: string;
    perPageAgents: number;
    pageAgents: number;
    perPageDomains: number;
    pageDomains: number;
    perPageContacts: number;
    pageContacts: number;
  };
  productivity: {
    email_volumes: {
      direction: "asc" | "desc";
      sortBy: string;
    };
    conversations: {
      direction: "asc" | "desc";
      sortBy: string;
    };
    average_reply_times: {
      direction: "asc" | "desc";
      sortBy: string;
    };
    responsiveness: {
      direction: "asc" | "desc";
      sortBy: string;
    };
    activity: {
      direction: "asc" | "desc";
      sortBy: string;
    };
    perPage: number;
    page: number;
  };
  comparative: {
    direction: "asc" | "desc";
    perPage: number;
    page: number;
  };
  interactions: any;
  customer: {
    directionEmails: "asc" | "desc";
    sortByEmails: string;
    perPageEmails: number;
    pageEmails: number;
    directionDomains: "asc" | "desc";
    sortByDomains: string;
    perPageDomains: number;
    pageDomains: number;
  };
  sla: {
    direction: "asc" | "desc";
    sortBy: string;
    perPageAgents: number;
    pageAgents: number;
    perPageDomains: number;
    pageDomains: number;
    perPageContacts: number;
    pageContacts: number;
    init_ttr_goal: string;
    overall_ttr_goal: string;
    overall_ttc_goal: string;
  };
  trend: {
    perPage: number;
    page: number;
    showAgents: boolean;
  };
  mailLogs: {
    direction: "asc" | "desc";
    sortBy: string;
    perPage: number;
    page: number;
    unfilteredView: boolean;
  };
  messageLogs: {
    direction: "asc" | "desc";
    sortBy: string;
    perPage: number;
    page: number;
    unfilteredView: boolean;
  };
  reportId: number | null;
  viewId: number | null;
  savedReportId: number | null;
  savedReportType: App.Enums.ReportType | null;
  impersonator: number | null;
  reportFiltersParams: any;
};

const state: ThreadFiltersState = {
  minimal: false,
  productType: "success",
  isTrend: false,
  fixedThreadType: "",
  fixedModelTypes: [],
  fixedModelTypesCom: [],
  dates: [todayMinusDays(7), todayMinusDays(0)],
  date: todayMinusDays(1),
  periods: 12,
  periodType: "Months",
  modelSelectedFull: myCompany(),
  excludeCc: false,
  modelSelectedFullCom: anybody(),
  excludeCcCom: false,
  exclusively: false,
  search: "",
  modelSearch: "",
  modelComSearch: "",
  label: [],
  labelsAndOr: "AND",
  dealStage: null,
  touches: "0",
  lastTouchDate: null,
  nudges: "0",
  agent: null,
  dealOwner: null,
  dealName: "",
  dealValueFrom: null,
  dealValueTo: null,
  repliesOver: 0,
  closedOver: 0,
  messageRepliesOver: 0,
  noReplyFor: 0,
  threadType: threadType(),
  threadStatus: threadStatus(),
  waitingForResponse: waitingForResponse(),
  hasReplies: hasReplies(),
  classification: classification(),
  messageType: messageType(),
  goals: {
    first_reply_time_goal: 7200,
    overall_reply_time_goal: 14400,
    time_to_close_goal: 86400,
  },
  agents: [],
  agentsCom: [],
  leadSources: [],
  leadSourcesCom: [],
  teams: [],
  teamsCom: [],
  groupMailboxes: [],
  groupMailboxesCom: [],
  customerGroups: [],
  customerGroupsCom: [],
  customers: [],
  customersCom: [],
  otherEntities: [],
  isLoading: true,
  isSearching: false,
  initialFilterDataSet: false,
  defaultSortDirection: "desc",
  advancedFilters: {
    active: 0,
    total: 0,
  },
  emailAndDomainFilters: {
    domains: 0,
    emails: 0,
  },
  leadSourcesReport: leadSourcesReport(),
  salesRepReport: salesRepReport(),
  overview: overview(),
  productivity: productivity(),
  comparative: comparative(),
  interactions: {},
  customer: customer(),
  sla: sla({
    first_reply_time_goal: 7200,
    overall_reply_time_goal: 14400,
    time_to_close_goal: 86400,
  }),
  trend: trend(),
  mailLogs: mailLogs(),
  messageLogs: messageLogs(),
  reportId: null,
  viewId: null,
  savedReportId: null,
  savedReportType: null,
  impersonator: null,
  reportFiltersParams: {},
};

export type ThreadFiltersGetters = {
  productType(state: ThreadFiltersState, getters: ThreadFiltersGetters, rootState: RootState, rootGetters: RootGetters): App.Enums.ProductType | null;
  agentsList(state: ThreadFiltersState, getters: ThreadFiltersGetters, rootState: RootState, rootGetters: RootGetters): any[];
  alls(state: ThreadFiltersState, getters: ThreadFiltersGetters, rootState: RootState, rootGetters: RootGetters): any[];
  allsCom(state: ThreadFiltersState, getters: ThreadFiltersGetters, rootState: RootState, rootGetters: RootGetters): any[];
  activeFiltersToolTip(state: ThreadFiltersState, getters: ThreadFiltersGetters, rootState: RootState, rootGetters: RootGetters): string;
  domainFiltersToolTip(state: ThreadFiltersState, getters: ThreadFiltersGetters, rootState: RootState, rootGetters: RootGetters): string;
  emailFiltersToolTip(state: ThreadFiltersState, getters: ThreadFiltersGetters, rootState: RootState, rootGetters: RootGetters): string;
  showExclusive(state: ThreadFiltersState, getters: ThreadFiltersGetters, rootState: RootState, rootGetters: RootGetters): boolean;
  showCC(state: ThreadFiltersState, getters: ThreadFiltersGetters, rootState: RootState, rootGetters: RootGetters): boolean;
  showCCCom(state: ThreadFiltersState, getters: ThreadFiltersGetters, rootState: RootState, rootGetters: RootGetters): boolean;
  utcDate(state: ThreadFiltersState, getters: ThreadFiltersGetters, rootState: RootState, rootGetters: RootGetters): string;
  utcFrom(state: ThreadFiltersState, getters: ThreadFiltersGetters, rootState: RootState, rootGetters: RootGetters): string;
  utcTo(state: ThreadFiltersState, getters: ThreadFiltersGetters, rootState: RootState, rootGetters: RootGetters): string;
  dateRangeFormatted(state: ThreadFiltersState, getters: ThreadFiltersGetters, rootState: RootState, rootGetters: RootGetters): string;
  classificationList(state: ThreadFiltersState, getters: ThreadFiltersGetters, rootState: RootState, rootGetters: RootGetters): string;
  messageTypeList(state: ThreadFiltersState, getters: ThreadFiltersGetters, rootState: RootState, rootGetters: RootGetters): string;
  threadTypeList(state: ThreadFiltersState, getters: ThreadFiltersGetters, rootState: RootState, rootGetters: RootGetters): string;
  threadStatusList(state: ThreadFiltersState, getters: ThreadFiltersGetters, rootState: RootState, rootGetters: RootGetters): string;
  waitingForResponseList(state: ThreadFiltersState, getters: ThreadFiltersGetters, rootState: RootState, rootGetters: RootGetters): string;
  hasRepliesList(state: ThreadFiltersState, getters: ThreadFiltersGetters, rootState: RootState, rootGetters: RootGetters): string;
  searchParams(state: ThreadFiltersState, getters: ThreadFiltersGetters, rootState: RootState, rootGetters: RootGetters): SearchParams;
  advancedFiltersCount(state: ThreadFiltersState, getters: ThreadFiltersGetters, rootState: RootState, rootGetters: RootGetters): number;
};

const getters: GetterTree<ThreadFiltersState, RootState> & ThreadFiltersGetters = {
  productType: (state: ThreadFiltersState): App.Enums.ProductType | null => {
    return state.productType;
  },
  agentsList: (state: ThreadFiltersState): any[] => {
    return state.agents;
  },
  alls: (state: ThreadFiltersState): any[] => {
    const cannotBe = state.modelSelectedFullCom.name === "Everybody Else" ? ["Anybody", "External"] : ["Anybody"];

    const entities = state.otherEntities
      .filter((entity) => {
        return !cannotBe.includes(entity.model_type) && entity.name.toLowerCase().includes(state.modelSearch.toLowerCase());
      })
      .concat(state.agents)
      .concat(state.leadSources)
      .concat(state.teams)
      .concat(state.groupMailboxes)
      .concat(state.customerGroups)
      .concat(state.customers);
    if (state.fixedModelTypes.length > 0) {
      return entities.filter((entity) => {
        return typeof entity !== "undefined" && state.fixedModelTypes.includes(entity.model_type);
      });
    }
    return entities;
  },
  allsCom: (state: ThreadFiltersState): any[] => {
    const cannotBe = state.modelSelectedFull.name === "Everybody Else" ? "External" : "";

    const entities = state.otherEntities
      .filter((entity) => {
        return entity.model_type !== cannotBe && entity.name.toLowerCase().includes(state.modelComSearch.toLowerCase());
      })
      .concat(state.agentsCom)
      .concat(state.leadSourcesCom)
      .concat(state.teamsCom)
      .concat(state.groupMailboxesCom)
      .concat(state.customerGroupsCom)
      .concat(state.customersCom);

    if (state.fixedModelTypesCom.length > 0) {
      return entities.filter((entity) => {
        return typeof entity !== "undefined" && state.fixedModelTypesCom.includes(entity.model_type);
      });
    }

    return entities;
  },

  activeFiltersToolTip: (state: ThreadFiltersState): string => {
    return state.advancedFilters.active + " of " + state.advancedFilters.total + " advanced filters active";
  },

  domainFiltersToolTip: (state: ThreadFiltersState): string => {
    return state.emailAndDomainFilters.domains + " domain filters active";
  },

  emailFiltersToolTip: (state: ThreadFiltersState): string => {
    return state.emailAndDomainFilters.emails + " email address filters active";
  },

  showExclusive: (state: ThreadFiltersState): boolean => {
    if (state.minimal) {
      return false;
    }
    return ![null, "Anybody", "External"].includes(state.modelSelectedFullCom.model_type) && ![null, "External"].includes(state.modelSelectedFull.model_type);
  },

  showCC: (state: ThreadFiltersState): boolean => {
    if (state.minimal) {
      return false;
    }
    return ![null, "Internal", "External"].includes(state.modelSelectedFull.model_type);
  },

  showCCCom: (state: ThreadFiltersState): boolean => {
    if (state.minimal) {
      return false;
    }
    return ![null, "Internal", "External", "Anybody"].includes(state.modelSelectedFullCom.model_type);
  },
  utcDate: (state: ThreadFiltersState): string => {
    return startOfDayYMDFormat(state.date);
  },
  utcFrom: (state: ThreadFiltersState): string => {
    return startOfDayYMDFormat(state.dates[0]);
  },
  utcTo: (state: ThreadFiltersState): string => {
    if (state.dates[1] !== null) {
      return startOfDayYMDFormat(state.dates[1]);
    }
    return startOfDayYMDFormat(state.dates[0]);
  },
  dateRangeFormatted: (state: ThreadFiltersState): string => {
    let secondDate = state.dates[1];
    if (state.dates[1] === null) {
      secondDate = state.dates[0];
    }
    return getDateLocalized(state.dates[0]) + " - " + getDateLocalized(secondDate);
  },
  classificationList: (state: ThreadFiltersState): string => {
    return state.classification.join();
  },
  messageTypeList: (state: ThreadFiltersState): string => {
    return state.messageType.join();
  },
  threadTypeList: (state: ThreadFiltersState): string => {
    return state.threadType.join();
  },
  threadStatusList: (state: ThreadFiltersState): string => {
    return state.threadStatus.join();
  },
  waitingForResponseList: (state: ThreadFiltersState): string => {
    return state.waitingForResponse.join();
  },
  hasRepliesList: (state: ThreadFiltersState): string => {
    return state.hasReplies.join();
  },
  searchParams(state: ThreadFiltersState, getters: ThreadFiltersGetters): SearchParams {
    const modelId = get(state.modelSelectedFull, "id");
    const model = Number.isInteger(modelId) && modelId !== null ? modelId : state.modelSelectedFull.name;

    const modelIdCom = get(state.modelSelectedFullCom, "id");
    const modelCom = Number.isInteger(modelIdCom) && modelIdCom !== null ? modelIdCom : state.modelSelectedFullCom.name;

    const params: any = {
      model: model.toString(),
      model_type: state.modelSelectedFull.model_type,
      model_com: modelCom.toString(),
      model_type_com: state.modelSelectedFullCom.model_type,
      label: state.label,
      labels_and_or: state.labelsAndOr,
      include_setup: true,
    };

    if (state.minimal) {
      params.date = getters.utcDate;
    } else {
      params.from = getters.utcFrom;
      params.to = getters.utcTo;
      params.exclude_cc = state.excludeCc;
      params.exclude_cc_com = state.excludeCcCom;
      params.exclusive = state.exclusively;
      params.search = state.search;
      params.thread_type = getters.threadTypeList;
      params.thread_status = getters.threadStatusList;
      params.waiting_for_response = getters.waitingForResponseList;
      params.has_replies = getters.hasRepliesList;
      params.classification = getters.classificationList;
      params.messageType = getters.messageTypeList;
      params.replies_over = state.repliesOver;
      params.closed_over = state.closedOver;
      params.message_replies_over = state.messageRepliesOver;
      params.no_reply_for = state.noReplyFor;
    }

    if (state.isTrend) {
      params.date = getters.utcDate;
      params.periods = state.periods;
      params.period_type = state.periodType;
      delete params.from;
      delete params.to;
    }

    if (state.productType === "ignite") {
      params.dealStage = state.dealStage;
      params.touches = state.touches;
      params.lastTouchDate = state.lastTouchDate;
      params.nudges = state.nudges;
      params.dealOwner = state.dealOwner?.id ?? null;
      params.agent = state.agent ?? null;
      params.dealName = state.dealName;
      params.dealValueFrom = state.dealValueFrom;
      params.dealValueTo = state.dealValueTo;
    }

    if (typeof impersonating !== "undefined") {
      params.i = impersonating;
    }

    if (typeof impersonatedBy !== "undefined") {
      params.u = impersonatedBy;
    }

    if (typeof state.reportId === "number") {
      params.reportId = null;
    }

    if (typeof state.viewId === "number") {
      params.viewId = null;
    }

    if (typeof state.impersonator === "number") {
      params.i = "true";
      params.u = state.impersonator;
    }

    return params;
  },

  advancedFiltersCount: (state: ThreadFiltersState): number => {
    let count = 0;
    if (state.label.length > 0) {
      count++;
    }
    if (state.search !== "") {
      count++;
    }
    if (state.repliesOver > 0) {
      count++;
    }
    if (state.closedOver > 0) {
      count++;
    }
    if (state.messageRepliesOver > 0) {
      count++;
    }
    if (state.noReplyFor > 0) {
      count++;
    }
    if (difference(threadType(), state.threadType).length > 0) {
      count++;
    }
    if (difference(threadStatus(), state.threadStatus).length > 0) {
      count++;
    }
    if (difference(waitingForResponse(), state.waitingForResponse).length > 0) {
      count++;
    }
    if (difference(hasReplies(), state.hasReplies).length > 0) {
      count++;
    }
    if (difference(classification(), state.classification).length > 0) {
      count++;
    }
    if (difference(messageType(), state.messageType).length > 0) {
      count++;
    }
    if (state.dealStage !== null) {
      count++;
    }
    if (parseInt(state.touches) > 0) {
      count++;
    }
    if (state.lastTouchDate !== null) {
      count++;
    }
    if (parseInt(state.nudges) > 0) {
      count++;
    }
    if (state.dealOwner !== null) {
      count++;
    }
    if (state.dealName !== "" && state.dealName !== null) {
      count++;
    }
    if (state.dealValueFrom !== null || state.dealValueTo !== null) {
      count++;
    }

    return count;
  },
};

export type ThreadFiltersMutations<S = ThreadFiltersState> = {
  setProductType(state: S, productType: App.Enums.ProductType | null): void;
  setMinimal(state: S, minimal: boolean): void;
  setIsTrend(state: S, isTrend: boolean): void;
  setFixedThreadType(state: S, fixedThreadType: App.Enums.ThreadType): void;
  setFixedModelTypes(state: S, fixedModelTypes: string[]): void;
  setFixedModelTypesCom(state: S, fixedModelTypes: string[]): void;
  setLoading(state: S, bool: boolean): void;
  addCustomer(state: S, customer: any): void;
  addCustomerCom(state: S, customer: any): void;
  clearAgents(state: S): void;
  clearAgentsCom(state: S): void;
  addAgent(state: S, agent: any): void;
  addAgentCom(state: S, agent: any): void;
  clearLeadSources(state: S): void;
  clearLeadSourcesCom(state: S): void;
  addLeadSource(state: S, leadSource: any): void;
  addLeadSourceCom(state: S, leadSource: any): void;
  setProductivityResponsivenessSort(state: S, goal: number): void;
  setGoals(state: S, goals: any): void;
  setSearching(state: S, searching: boolean): void;
  clearTeams(state: S): void;
  clearTeamsCom(state: S): void;
  addTeam(state: S, team: any): void;
  addTeamCom(state: S, team: any): void;
  clearGroupMailboxes(state: S): void;
  clearGroupMailboxesCom(state: S): void;
  setModelSearch(state: S, modelSearch: string): void;
  setModelComSearch(state: S, modelComSearch: string): void;
  clearCustomers(state: S): void;
  clearCustomersCom(state: S): void;
  clearCustomerGroups(state: S): void;
  clearCustomerGroupsCom(state: S): void;
  addGroupMailbox(state: S, groupMailbox: any): void;
  addGroupMailboxCom(state: S, groupMailbox: any): void;
  addCustomerGroup(state: S, customerGroup: any): void;
  addCustomerGroupCom(state: S, customerGroup: any): void;
  setEmailAndDomainFilters(state: S, emailAndDomainFilters: any): void;
  setReportId(state: S, reportId: number | null): void;
  setViewId(state: S, viewId: number | null): void;
  setSavedReportId(state: S, savedReportId: number | null): void;
  setSavedReportType(state: S, savedReportType: App.Enums.ReportType | null): void;
  setImpersonator(state: S, impersonator: number | null): void;
  setAdvancedFilters(state: S, advancedFilters: any): void;
  setDates(state: S, dates: [Date, Date]): void;
  setDate(state: S, date: Date): void;
  setPeriods(state: S, periods: number): void;
  setPeriodType(state: S, periodType: string): void;
  setInteractions(state: S, interactions: any): void;
  setModelSelectedFull(state: S, selected: any): void;
  setExcludeCc(state: S, excludeCc: boolean): void;
  setModelSelectedComFull(state: S, selected: any): void;
  setExcludeCcCom(state: S, excludeCc: boolean): void;
  setExclusively(state: S, exclusively: boolean): void;
  setSearch(state: S, search: string): void;
  setLabel(state: S, labels: string[]): void;
  setLabelsAndOr(state: S, labelsAndOr: string): void;
  setDealStage(state: S, dealStage: number): void;
  setTouches(state: S, touches: string): void;
  setLastTouchDate(state: S, lastTouchDate: string | null): void;
  setNudges(state: S, nudges: string): void;
  setDealOwner(state: S, dealOwner: App.DataObjects.ElasticsearchEntityObject | null): void;
  setDealName(state: S, dealName: string): void;
  setDealValueFrom(state: S, dealValueFrom: number | string | null): void;
  setDealValueTo(state: S, dealValueTo: number | string | null): void;
  setThreadType(state: S, threadType: App.Enums.ThreadType[]): void;
  setThreadStatus(state: S, threadStatus: App.Enums.ThreadStatus[]): void;
  setWaitingForResponse(state: S, waitingForResponse: string[]): void;
  setHasReplies(state: S, hasReplies: string[]): void;
  setOverview(state: S, overview: any): void;
  setProductivity(state: S, productivity: any): void;
  setComparative(state: S, comparative: any): void;
  setMailLogs(state: S, mailLogs: any): void;
  setClassification(state: S, classification: string[]): void;
  setMessageType(state: S, messageType: string[]): void;
  setRepliesOver(state: S, repliesOver: number): void;
  setClosedOver(state: S, closedOver: number): void;
  setMessageRepliesOver(state: S, messageRepliesOver: number): void;
  setNoReplyFor(state: S, noReplyFor: number): void;
  setMessageLogs(state: S, messageLogs: any): void;
  setSalesRepReport(state: S, salesRepReport: any): void;
  setSla(state: S, sla: any): void;
  setCustomer(state: S, customer: any): void;
  setTrend(state: S, trend: any): void;
  setSlaInitTTR(state: S, goal: string): void;
  setSlaOverallTTR(state: S, goal: string): void;
  setSlaOverallTTC(state: S, goal: string): void;
  setInitialFilterDataSet(state: S, bool: boolean): void;
  setReportFiltersParams(state: S, params: any): void;
};

const mutations: MutationTree<ThreadFiltersState> & ThreadFiltersMutations = {
  setProductType(state, productType) {
    state.productType = productType;
    state.otherEntities = [];
    const otherEntities = state.productType === "success" ? [anybody(), myCompany(), myDomains(), everybodyElse()] : [allLeads(), myCompany(), myDomains()];
    otherEntities.forEach((entity) => state.otherEntities.push(entity));

    const allLeadsObj: App.DataObjects.ElasticsearchEntityObject = allLeads();
    const anybodyObj: App.DataObjects.ElasticsearchEntityObject = anybody();

    if (state.productType === "success" && state.modelSelectedFullCom.name === allLeadsObj.name) {
      state.modelSelectedFullCom = anybodyObj;
    }
    if (state.productType === "ignite" && state.modelSelectedFullCom.name === anybodyObj.name) {
      state.modelSelectedFullCom = allLeadsObj;
    }
  },

  setMinimal(state, minimal) {
    state.minimal = minimal;
  },

  setIsTrend(state, isTrend) {
    state.isTrend = isTrend;
  },

  setFixedThreadType(state, fixedThreadType) {
    state.fixedThreadType = fixedThreadType;
  },

  setFixedModelTypes(state, fixedModelTypes) {
    state.fixedModelTypes = fixedModelTypes;
    if (fixedModelTypes.length > 0 && state.modelSelectedFull.model_type !== null && !fixedModelTypes.includes(state.modelSelectedFull.model_type)) {
      state.modelSelectedFull = myCompany();
    }
  },

  setFixedModelTypesCom(state, fixedModelTypes) {
    state.fixedModelTypesCom = fixedModelTypes;
    if (fixedModelTypes.length > 0 && state.modelSelectedFullCom.model_type !== null && !fixedModelTypes.includes(state.modelSelectedFullCom.model_type)) {
      state.modelSelectedFullCom = state.productType === "success" ? anybody() : allLeads();
    }
  },

  setLoading(state, bool) {
    state.isLoading = bool;
  },

  addCustomer(state, customer) {
    customer.value = customer.id + ".customer";
    state.customers.push(customer);
  },

  addCustomerCom(state, customer) {
    customer.value = customer.id + ".customer";
    state.customersCom.push(customer);
  },

  clearAgents(state) {
    state.agents = [];
  },

  clearAgentsCom(state) {
    state.agentsCom = [];
  },

  addAgent(state, agent) {
    agent.value = agent.id + ".agent";
    state.agents.push(agent);
  },

  addAgentCom(state, agent) {
    agent.value = agent.id + ".agent";
    state.agentsCom.push(agent);
  },

  clearLeadSources(state) {
    state.leadSources = [];
  },

  clearLeadSourcesCom(state) {
    state.leadSourcesCom = [];
  },

  addLeadSource(state, leadSource) {
    leadSource.value = leadSource.id + ".lead_source";
    state.leadSources.push(leadSource);
  },

  addLeadSourceCom(state, leadSource) {
    leadSource.value = leadSource.id + ".lead_source";
    state.leadSourcesCom.push(leadSource);
  },

  setProductivityResponsivenessSort(state, goal) {
    state.productivity.responsiveness.sortBy = "replies_under_" + goal;
  },

  setGoals(state, goals) {
    state.goals.first_reply_time_goal = get(goals, "first_reply_time_goal", 7200);
    state.goals.overall_reply_time_goal = get(goals, "overall_reply_time_goal", 14400);
    state.goals.time_to_close_goal = get(goals, "time_to_close_goal", 86400);
  },

  setSearching(state, searching) {
    state.isSearching = searching;
  },

  clearTeams(state) {
    state.teams = [];
  },

  clearTeamsCom(state) {
    state.teamsCom = [];
  },

  addTeam(state, team) {
    team.value = team.id + ".team";
    state.teams.push(team);
  },

  addTeamCom(state, team) {
    team.value = team.id + ".team";
    state.teamsCom.push(team);
  },

  clearGroupMailboxes(state) {
    state.groupMailboxes = [];
  },

  clearGroupMailboxesCom(state) {
    state.groupMailboxesCom = [];
  },

  setModelSearch(state, modelSearch) {
    state.modelSearch = modelSearch;
  },

  setModelComSearch(state, modelComSearch) {
    state.modelComSearch = modelComSearch;
  },

  clearCustomers(state) {
    state.customers = [];
  },

  clearCustomersCom(state) {
    state.customersCom = [];
  },

  clearCustomerGroups(state) {
    state.customerGroups = [];
  },

  clearCustomerGroupsCom(state) {
    state.customerGroupsCom = [];
  },

  addGroupMailbox(state, groupMailbox) {
    groupMailbox.value = groupMailbox.id + ".group_mailbox";
    state.groupMailboxes.push(groupMailbox);
  },

  addGroupMailboxCom(state, groupMailbox) {
    groupMailbox.value = groupMailbox.id + ".group_mailbox";
    state.groupMailboxesCom.push(groupMailbox);
  },

  addCustomerGroup(state, customerGroup) {
    customerGroup.value = customerGroup.id + ".customer_group";
    state.customerGroups.push(customerGroup);
  },

  addCustomerGroupCom(state, customerGroup) {
    customerGroup.value = customerGroup.id + ".customer_group";
    state.customerGroupsCom.push(customerGroup);
  },

  setEmailAndDomainFilters(state, emailAndDomainFilters) {
    state.emailAndDomainFilters = emailAndDomainFilters;
  },

  setReportId(state, reportId) {
    state.reportId = reportId;
  },

  setViewId(state, viewId) {
    state.viewId = viewId;
  },

  setSavedReportId(state, savedReportId) {
    state.savedReportId = savedReportId;
  },

  setSavedReportType(state, savedReportType) {
    state.savedReportType = savedReportType;
  },

  setImpersonator(state, impersonator) {
    state.impersonator = impersonator;
  },

  setAdvancedFilters(state, advancedFilters) {
    state.advancedFilters = advancedFilters;
  },

  setDates(state, dates) {
    state.dates = dates;
  },

  setDate(state, date) {
    state.date = date;
  },

  setPeriods(state, periods) {
    state.periods = periods;
    if (state.periodType === "Months" && periods > 12) {
      state.periods = 12;
    }
  },

  setPeriodType(state, periodType) {
    state.periodType = periodType;
    if (periodType === "Months" && state.periods > 12) {
      state.periods = 12;
    }
  },

  setInteractions(state, interactions) {
    state.interactions = interactions;
  },

  setModelSelectedFull(state, selected: App.DataObjects.ElasticsearchEntityObject | null) {
    if (selected != null) {
      state.modelSelectedFull = selected;
    }
  },

  setExcludeCc(state, excludeCc) {
    state.excludeCc = excludeCc;
  },

  setModelSelectedComFull(state, selected: App.DataObjects.ElasticsearchEntityObject | null) {
    if (selected != null) {
      state.modelSelectedFullCom = selected;
    }
  },

  setExcludeCcCom(state, excludeCc) {
    state.excludeCcCom = excludeCc;
  },

  setExclusively(state, exclusively) {
    state.exclusively = exclusively;
  },

  setSearch(state, search) {
    state.search = search;
  },

  setLabel(state, labels) {
    state.label = labels;
  },

  setLabelsAndOr(state, labelsAndOr) {
    state.labelsAndOr = labelsAndOr;
  },

  setDealStage(state, dealStage) {
    state.dealStage = dealStage;
  },

  setTouches(state, touches) {
    state.touches = touches;
  },

  setLastTouchDate(state, lastTouchDate) {
    state.lastTouchDate = lastTouchDate;
  },

  setNudges(state, nudges) {
    state.nudges = nudges;
  },

  setDealOwner(state, dealOwner) {
    state.dealOwner = dealOwner;
    state.agent = dealOwner;
  },

  setDealName(state, dealName) {
    state.dealName = dealName;
  },

  setDealValueFrom(state, dealValueFrom) {
    if (state.dealValueTo !== null && dealValueFrom !== null && dealValueFrom !== "") {
      dealValueFrom = Math.min(Number(dealValueFrom), state.dealValueTo);
    }
    state.dealValueFrom = dealValueFrom === null ? null : Number(dealValueFrom);
  },

  setDealValueTo(state, dealValueTo) {
    if (state.dealValueFrom !== null && dealValueTo !== null && dealValueTo !== "") {
      dealValueTo = Math.max(Number(dealValueTo), state.dealValueFrom);
    }
    state.dealValueTo = dealValueTo === null ? null : Number(dealValueTo);
  },

  setThreadType(state, threadType) {
    if (state.fixedThreadType === "") {
      state.threadType = threadType;
    } else {
      state.threadType = [state.fixedThreadType];
    }
  },

  setThreadStatus(state, threadStatus) {
    state.threadStatus = threadStatus;
  },

  setWaitingForResponse(state, waitingForResponse) {
    state.waitingForResponse = waitingForResponse;
  },

  setHasReplies(state, hasReplies) {
    state.hasReplies = hasReplies;
  },

  setOverview(state, overview) {
    const clonedOverview = cloneDeep(state.overview);
    if (has(overview, "direction")) {
      clonedOverview.direction = overview.direction;
    }
    if (has(overview, "sortBy")) {
      clonedOverview.sortBy = overview.sortBy;
    }
    if (has(overview, "perPageAgents")) {
      clonedOverview.perPageAgents = parseInt(overview.perPageAgents);
    }
    if (has(overview, "pageAgents")) {
      clonedOverview.pageAgents = parseInt(overview.pageAgents);
    }
    if (has(overview, "perPageContacts")) {
      clonedOverview.perPageContacts = parseInt(overview.perPageContacts);
    }
    if (has(overview, "pageContacts")) {
      clonedOverview.pageContacts = parseInt(overview.pageContacts);
    }
    if (has(overview, "perPageDomains")) {
      clonedOverview.perPageDomains = parseInt(overview.perPageDomains);
    }
    if (has(overview, "pageDomains")) {
      clonedOverview.pageDomains = parseInt(overview.pageDomains);
    }
    state.overview = clonedOverview;
  },

  setProductivity(state, productivity) {
    const clonedProductivity = cloneDeep(state.productivity);
    if (has(productivity, "email_volumes.direction")) {
      clonedProductivity.email_volumes.direction = productivity.email_volumes.direction;
    }
    if (has(productivity, "email_volumes.sortBy")) {
      clonedProductivity.email_volumes.sortBy = productivity.email_volumes.sortBy;
    }
    if (has(productivity, "conversations.direction")) {
      clonedProductivity.conversations.direction = productivity.conversations.direction;
    }
    if (has(productivity, "conversations.sortBy")) {
      clonedProductivity.conversations.sortBy = productivity.conversations.sortBy;
    }
    if (has(productivity, "average_reply_times.direction")) {
      clonedProductivity.average_reply_times.direction = productivity.average_reply_times.direction;
    }
    if (has(productivity, "average_reply_times.sortBy")) {
      clonedProductivity.average_reply_times.sortBy = productivity.average_reply_times.sortBy;
    }
    if (has(productivity, "responsiveness.direction")) {
      clonedProductivity.responsiveness.direction = productivity.responsiveness.direction;
    }
    if (has(productivity, "responsiveness.sortBy")) {
      clonedProductivity.responsiveness.sortBy = productivity.responsiveness.sortBy;
    }
    if (has(productivity, "activity.direction")) {
      clonedProductivity.activity.direction = productivity.activity.direction;
    }
    if (has(productivity, "activity.sortBy")) {
      clonedProductivity.activity.sortBy = productivity.activity.sortBy;
    }
    if (has(productivity, "perPage")) {
      clonedProductivity.perPage = productivity.perPage;
    }
    if (has(productivity, "page")) {
      clonedProductivity.page = productivity.page;
    }
    state.productivity = clonedProductivity;
  },

  setComparative(state, comparative) {
    const clonedComparative = cloneDeep(state.comparative);
    if (has(comparative, "direction")) {
      clonedComparative.direction = comparative.direction;
    }
    if (has(comparative, "perPage")) {
      clonedComparative.perPage = comparative.perPage;
    }
    if (has(comparative, "page")) {
      clonedComparative.page = comparative.page;
    }
    state.comparative = clonedComparative;
  },

  setMailLogs(state, mailLogs) {
    const clonedMailLogs = cloneDeep(state.mailLogs);
    if (has(mailLogs, "direction")) {
      clonedMailLogs.direction = mailLogs.direction;
    }
    if (has(mailLogs, "sortBy")) {
      clonedMailLogs.sortBy = mailLogs.sortBy;
    }
    if (has(mailLogs, "perPage")) {
      clonedMailLogs.perPage = mailLogs.perPage;
    }
    if (has(mailLogs, "page")) {
      clonedMailLogs.page = mailLogs.page;
    }
    if (has(mailLogs, "unfilteredView")) {
      clonedMailLogs.unfilteredView = mailLogs.unfilteredView;
    }
    state.mailLogs = clonedMailLogs;
  },

  setClassification(state, classification) {
    state.classification = classification;
  },

  setMessageType(state, messageType) {
    state.messageType = messageType;
  },

  setRepliesOver(state, repliesOver) {
    state.repliesOver = repliesOver;
  },

  setClosedOver(state, closedOver) {
    state.closedOver = closedOver;
  },

  setMessageRepliesOver(state, messageRepliesOver) {
    state.messageRepliesOver = messageRepliesOver;
    if (messageRepliesOver > 0) {
      state.noReplyFor = 0;
    }
  },

  setNoReplyFor(state, noReplyFor) {
    state.noReplyFor = noReplyFor;
    if (noReplyFor > 0) {
      state.messageRepliesOver = 0;
    }
  },

  setMessageLogs(state, messageLogs) {
    const clonedMessageLogs = cloneDeep(state.messageLogs);
    if (has(messageLogs, "direction")) {
      clonedMessageLogs.direction = messageLogs.direction;
    }
    if (has(messageLogs, "sortBy")) {
      clonedMessageLogs.sortBy = messageLogs.sortBy;
    }
    if (has(messageLogs, "perPage")) {
      clonedMessageLogs.perPage = messageLogs.perPage;
    }
    if (has(messageLogs, "page")) {
      clonedMessageLogs.page = messageLogs.page;
    }
    if (has(messageLogs, "unfilteredView")) {
      clonedMessageLogs.unfilteredView = messageLogs.unfilteredView;
    }
    state.messageLogs = clonedMessageLogs;
  },

  setSalesRepReport(state, salesRepReport) {
    const clonedSalesRepReport = cloneDeep(state.salesRepReport);
    if (has(salesRepReport, "direction")) {
      clonedSalesRepReport.direction = salesRepReport.direction;
    }
    if (has(salesRepReport, "sortBy")) {
      clonedSalesRepReport.sortBy = salesRepReport.sortBy;
    }
    if (has(salesRepReport, "perPage")) {
      clonedSalesRepReport.perPage = salesRepReport.perPage;
    }
    if (has(salesRepReport, "page")) {
      clonedSalesRepReport.page = salesRepReport.page;
    }
    state.salesRepReport = clonedSalesRepReport;
  },

  setSla(state, sla) {
    const clonedSla = cloneDeep(state.sla);
    if (has(sla, "direction")) {
      clonedSla.direction = sla.direction;
    }
    if (has(sla, "sortBy")) {
      clonedSla.sortBy = sla.sortBy;
    }
    if (has(sla, "perPageAgents")) {
      clonedSla.perPageAgents = sla.perPageAgents;
    }
    if (has(sla, "pageAgents")) {
      clonedSla.pageAgents = sla.pageAgents;
    }
    if (has(sla, "perPageContacts")) {
      clonedSla.perPageContacts = sla.perPageContacts;
    }
    if (has(sla, "pageContacts")) {
      clonedSla.pageContacts = sla.pageContacts;
    }
    if (has(sla, "init_ttr_goal")) {
      if (typeof sla.init_ttr_goal === "string") {
        clonedSla.init_ttr_goal = sla.init_ttr_goal;
      } else {
        clonedSla.init_ttr_goal = secondsToTimeStringShort(sla.init_ttr_goal);
      }
    }
    if (has(sla, "overall_ttr_goal")) {
      if (typeof sla.overall_ttr_goal === "string") {
        clonedSla.overall_ttr_goal = sla.overall_ttr_goal;
      } else {
        clonedSla.overall_ttr_goal = secondsToTimeStringShort(sla.overall_ttr_goal);
      }
    }
    if (has(sla, "overall_ttc_goal")) {
      if (typeof sla.overall_ttc_goal === "string") {
        clonedSla.overall_ttc_goal = sla.overall_ttc_goal;
      } else {
        clonedSla.overall_ttc_goal = secondsToTimeStringShort(sla.overall_ttc_goal);
      }
    }
    state.sla = clonedSla;
  },

  setCustomer(state, customer) {
    const clonedCustomer = cloneDeep(state.customer);
    if (has(customer, "pageEmails")) {
      clonedCustomer.pageEmails = customer.pageEmails;
    }
    if (has(customer, "perPageEmails")) {
      clonedCustomer.perPageEmails = customer.perPageEmails;
    }
    if (has(customer, "sortByEmails")) {
      clonedCustomer.sortByEmails = customer.sortByEmails;
    }
    if (has(customer, "directionEmails")) {
      clonedCustomer.directionEmails = customer.directionEmails;
    }
    if (has(customer, "pageDomains")) {
      clonedCustomer.pageDomains = customer.pageDomains;
    }
    if (has(customer, "perPageDomains")) {
      clonedCustomer.perPageDomains = customer.perPageDomains;
    }
    if (has(customer, "sortByDomains")) {
      clonedCustomer.sortByDomains = customer.sortByDomains;
    }
    if (has(customer, "directionDomains")) {
      clonedCustomer.directionDomains = customer.directionDomains;
    }
    state.customer = clonedCustomer;
  },

  setTrend(state, trend) {
    const clonedTrend = cloneDeep(state.trend);
    if (has(trend, "page")) {
      clonedTrend.page = trend.page;
    }
    if (has(trend, "perPage")) {
      clonedTrend.perPage = trend.perPage;
    }
    if (has(trend, "show_agents")) {
      clonedTrend.showAgents = trend.show_agents;
    }
    if (has(trend, "showAgents")) {
      clonedTrend.showAgents = trend.showAgents;
    }
    state.trend = clonedTrend;
  },

  setSlaInitTTR(state, goal) {
    state.sla.init_ttr_goal = goal;
  },

  setSlaOverallTTR(state, goal) {
    state.sla.overall_ttr_goal = goal;
  },

  setSlaOverallTTC(state, goal) {
    state.sla.overall_ttc_goal = goal;
  },

  setInitialFilterDataSet(state, bool) {
    state.initialFilterDataSet = bool;
  },

  setReportFiltersParams(state, params) {
    state.reportFiltersParams = params;
  },
};

export type ThreadFiltersActions = {
  loadAllData(context: ActionContext<ThreadFiltersState, RootState>, payload: any): void;
  setData(context: ActionContext<ThreadFiltersState, RootState>, data: any): void;
  searchAgents(context: ActionContext<ThreadFiltersState, RootState>, search: any): void;
  searchModels(context: ActionContext<ThreadFiltersState, RootState>, search: any): void;
  searchModelsCom(context: ActionContext<ThreadFiltersState, RootState>, search: any): void;
  reset(context: ActionContext<ThreadFiltersState, RootState>): void;
  resetMoreFilters(context: ActionContext<ThreadFiltersState, RootState>): void;
};

const actions: ActionTree<ThreadFiltersState, RootState> & ThreadFiltersActions = {
  loadAllData({commit, dispatch}: ActionContext<ThreadFiltersState, RootState>, payload: any) {
    commit("setLoading", true);

    const params: any = {
      include_session_filters: true,
    };
    if (typeof impersonating !== "undefined") {
      params.i = impersonating.toString();
    }

    if (typeof impersonatedBy !== "undefined") {
      params.u = impersonatedBy;
    }

    if (typeof payload !== "undefined") {
      if (has(payload, "impersonator") && typeof payload.impersonator == "number") {
        commit("setImpersonator", payload.impersonator);
        params.i = true.toString();
        params.u = payload.impersonator;
      }

      if (has(payload, "reportId") && typeof payload.reportId == "number") {
        commit("setReportId", payload.reportId);
        params.report_id = payload.reportId;
      }

      if (has(payload, "viewId") && typeof payload.viewId == "number") {
        commit("setViewId", payload.viewId);
        params.view_id = payload.viewId;
      }
    }

    if (filtersCancelController) {
      filtersCancelController.abort();
    }

    filtersCancelController = new AbortController();

    apiMessageFiltersAll(params, {signal: filtersCancelController.signal})
      .then((response) => {
        dispatch("setData", response.data);
      })
      .catch((error: AxiosError<LaravelValidationErrors | TtrAnErrorResponse>) => {
        if (error.code === "ERR_CANCELED") {
          return;
        }
        dispatch(
          "user/recordErrors",
          {
            data: "Your session has expired. We will now refresh the page to start a new session.",
            type: "toast",
          },
          {root: true},
        );
      })
      .finally(() => (filtersCancelController = undefined));
  },

  setData(
    {commit}: ActionContext<ThreadFiltersState, RootState>,
    data: {
      advanced_filters_count: {
        active: number;
        total: number;
      };
      domain_and_email_filters: {
        domains: number;
        emails: number;
      };
      agents: App.DataObjects.ElasticsearchEntityObject[];
      teams: App.DataObjects.ElasticsearchEntityObject[];
      group_mailboxes: App.DataObjects.ElasticsearchEntityObject[];
      customer_groups: App.DataObjects.ElasticsearchEntityObject[];
      customers: App.DataObjects.ElasticsearchEntityObject[];
      lead_sources: App.DataObjects.ElasticsearchEntityObject[];
      goals: App.DataObjects.CompanyReplyTimeGoalsDataObject;
      cached: boolean;
      impersonating: boolean;
      session_filters: any;
    },
  ) {
    if (has(data, "impersonator") && typeof data.impersonator == "number") {
      commit("setImpersonator", data.impersonator);
    }

    if (has(data, "reportId") && typeof data.reportId == "number") {
      commit("setReportId", data.reportId);
    }

    if (has(data, "viewId") && typeof data.viewId == "number") {
      commit("setViewId", data.viewId);
    }

    if (has(data, "agents")) {
      const agents = data.agents;
      commit("clearAgents");
      commit("clearAgentsCom");
      agents.forEach((agent: any) => {
        commit("addAgent", agent);
        commit("addAgentCom", agent);
      });
    }

    if (has(data, "lead_sources")) {
      const leadSources = data.lead_sources;
      commit("clearLeadSources");
      commit("clearLeadSourcesCom");
      leadSources.forEach((leadSource: any) => {
        commit("addLeadSource", leadSource);
        commit("addLeadSourceCom", leadSource);
      });
    }

    if (has(data, "goals")) {
      commit("setGoals", data.goals);
      commit("setProductivityResponsivenessSort", get(data.goals, "first_reply_time_goal", 7200));
    }

    if (has(data, "teams")) {
      const teams = data.teams;
      commit("clearTeams");
      commit("clearTeamsCom");
      teams.forEach((team: any) => {
        commit("addTeam", team);
        commit("addTeamCom", team);
      });
    }

    if (has(data, "group_mailboxes")) {
      const groupMailboxes = data.group_mailboxes;
      commit("clearGroupMailboxes");
      commit("clearGroupMailboxesCom");
      groupMailboxes.forEach((groupMailbox: any) => {
        commit("addGroupMailbox", groupMailbox);
        commit("addGroupMailboxCom", groupMailbox);
      });
    }

    if (has(data, "customers")) {
      const customers = data.customers;
      commit("clearCustomers");
      commit("clearCustomersCom");
      customers.forEach((customer: any) => {
        commit("addCustomer", customer);
        commit("addCustomerCom", customer);
      });
    }

    if (has(data, "customer_groups")) {
      const customerGroups = data.customer_groups;
      commit("clearCustomerGroups");
      commit("clearCustomerGroupsCom");
      customerGroups.forEach((customerGroup: any) => {
        commit("addCustomerGroup", customerGroup);
        commit("addCustomerGroupCom", customerGroup);
      });
    }

    if (has(data, "domain_and_email_filters")) {
      commit("setEmailAndDomainFilters", data.domain_and_email_filters);
    }

    if (has(data, "advanced_filters_count")) {
      commit("setAdvancedFilters", data.advanced_filters_count);
    }

    const sessionFilters = data.session_filters;

    let fromDate = todayMinusDays(7);
    let toDate = todayMinusDays(0);
    if (has(sessionFilters, "from")) {
      fromDate = convertStringToDate(sessionFilters.from);
    }
    if (has(sessionFilters, "to")) {
      toDate = convertStringToDate(sessionFilters.to);
    }
    commit("setDates", [fromDate, toDate]);
    commit("setDate", toDate);

    if (has(sessionFilters, "periodType")) {
      commit("setPeriodType", sessionFilters.periodType);
    }

    if (has(sessionFilters, "periods")) {
      commit("setPeriods", sessionFilters.periods);
    }

    if (has(sessionFilters, "modelFull") && sessionFilters.modelFull != null) {
      commit("setModelSelectedFull", sessionFilters.modelFull);
      if (has(sessionFilters, "excludeCc")) {
        commit("setExcludeCc", sessionFilters.excludeCc);
      }
    } else {
      commit("setModelSelectedFull", myCompany());
      commit("setExcludeCc", false);
    }
    if (has(sessionFilters, "modelComFull") && sessionFilters.modelComFull != null) {
      commit("setModelSelectedComFull", sessionFilters.modelComFull);
      if (has(sessionFilters, "excludeCcCom")) {
        commit("setExcludeCcCom", sessionFilters.excludeCcCom);
      }

      if (has(sessionFilters, "exclusively")) {
        commit("setExclusively", sessionFilters.exclusively);
      }
    } else {
      commit("setModelSelectedComFull", state.productType === "success" ? anybody() : allLeads());
      commit("setExcludeCcCom", false);
      commit("setExclusively", false);
    }
    if (has(sessionFilters, "search") && sessionFilters.search !== null) {
      commit("setSearch", sessionFilters.search);
    }
    if (has(sessionFilters, "label") && Array.isArray(sessionFilters.label)) {
      commit("setLabel", sessionFilters.label);
    }
    if (has(sessionFilters, "labelsAndOr")) {
      commit("setLabelsAndOr", sessionFilters.labelsAndOr);
    }
    if (has(sessionFilters, "dealStage")) {
      commit("setDealStage", sessionFilters.dealStage);
    }
    if (has(sessionFilters, "touches")) {
      const touches = sessionFilters.touches !== null ? sessionFilters.touches.toString() : null;
      commit("setTouches", touches);
    }
    if (has(sessionFilters, "lastTouchDate")) {
      commit("setLastTouchDate", sessionFilters.lastTouchDate);
    }
    if (has(sessionFilters, "nudges")) {
      const nudges = sessionFilters.nudges !== null ? sessionFilters.nudges.toString() : null;
      commit("setNudges", nudges);
    }
    if (has(sessionFilters, "dealOwner")) {
      commit("setDealOwner", sessionFilters.dealOwner);
    }
    if (has(sessionFilters, "dealName")) {
      commit("setDealName", sessionFilters.dealName);
    }
    if (has(sessionFilters, "dealValueFrom")) {
      commit("setDealValueFrom", sessionFilters.dealValueFrom);
    }
    if (has(sessionFilters, "dealValueTo")) {
      commit("setDealValueTo", sessionFilters.dealValueTo);
    }
    if (has(sessionFilters, "threadType")) {
      if (isArray(sessionFilters.threadType)) {
        commit("setThreadType", sessionFilters.threadType);
      } else {
        commit("setThreadType", sessionFilters.threadType.split(","));
      }
    }
    if (has(sessionFilters, "threadStatus")) {
      if (isArray(sessionFilters.threadStatus)) {
        commit("setThreadStatus", sessionFilters.threadStatus);
      } else {
        commit("setThreadStatus", sessionFilters.threadStatus.split(","));
      }
    }
    if (has(sessionFilters, "waitingForResponse")) {
      if (isArray(sessionFilters.waitingForResponse)) {
        commit("setWaitingForResponse", sessionFilters.waitingForResponse);
      } else {
        commit("setWaitingForResponse", sessionFilters.waitingForResponse.split(","));
      }
    }
    if (has(sessionFilters, "hasReplies")) {
      if (isArray(sessionFilters.hasReplies)) {
        commit("setHasReplies", sessionFilters.hasReplies);
      } else {
        commit("setHasReplies", sessionFilters.hasReplies.split(","));
      }
    }
    if (has(sessionFilters, "classification")) {
      if (isArray(sessionFilters.classification)) {
        commit("setClassification", sessionFilters.classification);
      } else {
        commit("setClassification", sessionFilters.classification.split(","));
      }
    }
    if (has(sessionFilters, "messageType")) {
      if (isArray(sessionFilters.messageType)) {
        commit("setMessageType", sessionFilters.messageType);
      } else {
        commit("setMessageType", sessionFilters.messageType.split(","));
      }
    }
    if (has(sessionFilters, "overview")) {
      commit("setOverview", sessionFilters.overview);
    }
    if (has(sessionFilters, "productivity")) {
      commit("setProductivity", sessionFilters.productivity);
    }
    if (has(sessionFilters, "comparative")) {
      commit("setComparative", sessionFilters.comparative);
    }

    if (has(sessionFilters, "mailLogs")) {
      commit("setMailLogs", sessionFilters.mailLogs);
    }
    if (has(sessionFilters, "messageLogs")) {
      commit("setMessageLogs", sessionFilters.messageLogs);
    }
    if (has(sessionFilters, "sla")) {
      commit("setSla", sessionFilters.sla);
    }
    if (has(sessionFilters, "trend")) {
      commit("setTrend", sessionFilters.trend);
    }
    if (has(sessionFilters, "repliesOver")) {
      commit("setRepliesOver", sessionFilters.repliesOver);
    }
    if (has(sessionFilters, "closedOver")) {
      commit("setClosedOver", sessionFilters.closedOver);
    }
    if (has(sessionFilters, "messageRepliesOver")) {
      commit("setMessageRepliesOver", sessionFilters.messageRepliesOver);
    }
    if (has(sessionFilters, "noReplyFor")) {
      commit("setNoReplyFor", sessionFilters.noReplyFor);
    }
    commit("setLoading", false);
  },

  searchAgents: debounce(({commit, state}: ActionContext<ThreadFiltersState, RootState>, search: any) => {
    const searchText = get(search, "text", search);
    if (searchText.length > 0) {
      commit("setSearching", true);

      if (entitiesSearchCancelController) {
        entitiesSearchCancelController.abort();
      }

      entitiesSearchCancelController = new AbortController();

      apiCompanyEntitiesSearch({search: searchText, type: "Agent", product_type: state.productType}, {signal: entitiesSearchCancelController.signal})
        .then((response) => {
          if (!isLaravelLengthAwarePaginator(response.data)) {
            return;
          }
          commit("clearAgents");
          response.data.data.forEach((item: any) => {
            switch (item["model_type"]) {
            case "Agent":
            case "Mailbox":
              commit("addAgent", item);
              break;
            default:
              // do nothing
              break;
            }
          });
        })
        .catch((error: AxiosError<LaravelValidationErrors | TtrAnErrorResponse>) => {
          if (error.code === "ERR_CANCELED") {
            return;
          }
          commit("clearAgents");
        })
        .finally(() => {
          commit("setSearching", false);
          entitiesSearchCancelController = undefined;
        });
    }
    commit("setSearching", false);
  }, 500),

  searchModels: debounce(({commit, state}: ActionContext<ThreadFiltersState, RootState>, search: any) => {
    const searchText = get(search, "text", search);
    commit("setModelSearch", searchText);
    const type = get(search, "type", "all");
    if (searchText.length > 0) {
      commit("setSearching", true);

      if (entitiesSearchCancelController) {
        entitiesSearchCancelController.abort();
      }

      entitiesSearchCancelController = new AbortController();

      apiCompanyEntitiesSearch({search: searchText, type: type, product_type: state.productType}, {signal: entitiesSearchCancelController.signal})
        .then((response) => {
          if (!isLaravelLengthAwarePaginator(response.data)) {
            return;
          }
          commit("clearAgents");
          commit("clearTeams");
          commit("clearGroupMailboxes");
          commit("clearCustomerGroups");
          commit("clearCustomers");
          response.data.data.forEach((item: any) => {
            switch (item["model_type"]) {
            case "Agent":
            case "Mailbox":
              commit("addAgent", item);
              break;
            case "Customer Group":
            case "Contact Group":
            case "Lead Group":
              commit("addCustomerGroup", item);
              break;
            case "Customer":
            case "Contact":
            case "Lead":
              commit("addCustomer", item);
              break;
            case "Group Mailbox":
              commit("addGroupMailbox", item);
              break;
            case "Team":
              commit("addTeam", item);
              break;
            default:
              // do nothing
              break;
            }
          });
        })
        .catch((error: AxiosError<LaravelValidationErrors | TtrAnErrorResponse>) => {
          if (error.code === "ERR_CANCELED") {
            return;
          }
          commit("clearAgents");
          commit("clearTeams");
          commit("clearGroupMailboxes");
          commit("clearCustomerGroups");
          commit("clearCustomers");
        })
        .finally(() => {
          commit("setSearching", false);
          entitiesSearchCancelController = undefined;
        });
    }
    commit("setSearching", false);
  }, 500),

  searchModelsCom: debounce(({commit, state}: ActionContext<ThreadFiltersState, RootState>, search: any) => {
    const searchText = get(search, "text", search);
    commit("setModelComSearch", searchText);
    const type = get(search, "type", "all");
    if (searchText.length > 0) {
      commit("setSearching", true);

      if (entitiesSearchCancelController) {
        entitiesSearchCancelController.abort();
      }

      entitiesSearchCancelController = new AbortController();

      apiCompanyEntitiesSearch({search: searchText, type: type, product_type: state.productType}, {signal: entitiesSearchCancelController.signal})
        .then((response) => {
          if (!isLaravelLengthAwarePaginator(response.data)) {
            return;
          }
          commit("clearAgentsCom");
          commit("clearTeamsCom");
          commit("clearGroupMailboxesCom");
          commit("clearCustomerGroupsCom");
          commit("clearCustomersCom");
          response.data.data.forEach((item: any) => {
            switch (item["model_type"]) {
            case "Agent":
            case "Mailbox":
              commit("addAgentCom", item);
              break;
            case "Customer Group":
            case "Contact Group":
            case "Lead Group":
              commit("addCustomerGroupCom", item);
              break;
            case "Customer":
            case "Contact":
            case "Lead":
              commit("addCustomerCom", item);
              break;
            case "Group Mailbox":
              commit("addGroupMailboxCom", item);
              break;
            case "Team":
              commit("addTeamCom", item);
              break;
            default:
              // do nothing
              break;
            }
          });
        })
        .catch((error: AxiosError<LaravelValidationErrors | TtrAnErrorResponse>) => {
          if (error.code === "ERR_CANCELED") {
            return;
          }
          commit("clearAgentsCom");
          commit("clearTeamsCom");
          commit("clearGroupMailboxesCom");
          commit("clearCustomerGroupsCom");
          commit("clearCustomersCom");
        })
        .finally(() => {
          commit("setSearching", false);
          entitiesSearchCancelController = undefined;
        });
    }
    commit("setSearching", false);
  }, 500),

  reset({commit, dispatch}: ActionContext<ThreadFiltersState, RootState>) {
    commit("setModelSelectedFull", myCompany());
    commit("setModelSelectedComFull", state.productType === "success" ? anybody() : allLeads());

    commit("setDates", [todayMinusDays(7), todayMinusDays(0)]);
    commit("setDate", todayMinusDays(1));
    commit("setPeriods", 12);
    commit("setPeriodType", "Months");

    commit("setOverview", overview());
    commit("setProductivity", productivity());
    commit("setComparative", comparative());
    commit("setInteractions", {});
    commit("setSla", sla(state.goals));
    commit("setTrend", trend());
    commit("setCustomer", customer());
    commit("setMailLogs", mailLogs());
    commit("setMessageLogs", messageLogs());

    commit("setSavedReportId", null);
    commit("setViewId", null);
    commit("setSavedReportType", null);

    dispatch("resetMoreFilters");
  },

  resetMoreFilters({commit, state}: ActionContext<ThreadFiltersState, RootState>) {
    commit("setSearch", "");
    commit("setLabel", []);
    commit("setLabelsAndOr", "AND");
    commit("setRepliesOver", 0);
    commit("setClosedOver", 0);
    commit("setMessageRepliesOver", 0);
    commit("setNoReplyFor", 0);
    if (!state.fixedThreadType) {
      commit("setThreadType", threadType());
    }
    commit("setThreadStatus", threadStatus());
    commit("setWaitingForResponse", waitingForResponse());
    commit("setHasReplies", hasReplies());
    commit("setClassification", classification());
    commit("setMessageType", messageType());
    commit("setDealStage", null);
    commit("setTouches", "0");
    commit("setLastTouchDate", null);
    commit("setNudges", "0");
    commit("setDealOwner", null);
    commit("setDealName", "");
    commit("setDealValueFrom", null);
    commit("setDealValueTo", null);
  },
};

const threadFilters: Module<ThreadFiltersState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
export default threadFilters;
