import {apiCompanyPreferenceUpdate, apiReportsOverviewIndex, apiReportsSlaIndex, apiSetupUpdate} from "@/js/http/api";
import {getErrorMessageFromValidationError} from "@/js/http/http";
import {BreakPoint, BreakPointType, allLeads, anybody, breakPointsDetails, defaultDayOfWeek, defaultHourOfDay, defaultPaginator, emptyLayout, myCompany} from "@/js/misc/defaultObjects";
import {trackEvent} from "@/js/misc/eventTracker";
import {calculatePosition, doItemsOverlap} from "@/js/store/common_functions/functions";
import {DailyStats, FrontEndWidgetDefinition, MailboxNames, RootGetters, RootState, SearchParams} from "@/js/store/types";
import {AxiosError} from "axios";
import {cloneDeep, find, findIndex, get, isEqual, sortBy} from "lodash-es";
import Vue from "vue";
import {ActionContext, ActionTree, GetterTree, Module, MutationTree} from "vuex";

let cancelController: AbortController | undefined = undefined;
let oldLayout = emptyLayout();

export const stats = (type: "stats" | "previousPeriod"): App.DataObjects.ReportResponses.Stats.Stats => ({
  dailyStats: [],
  initialTTR: {
    friendly: "N/A",
    friendly_no_business: "N/A",
    raw: null,
    raw_no_business: null,
    deviation_friendly: "N/A",
    deviation_friendly_no_business: "N/A",
    deviation_raw: null,
    deviation_raw_no_business: null,
    median_friendly: "N/A",
    median_friendly_no_business: "N/A",
    median_raw: null,
    median_raw_no_business: null,
    consistency_score: "N/A",
    consistency_score_no_business: "N/A",
    percentileRanks: [],
    percentileRanksRaw: [],
    within_sla: 0,
    within_sla_percentage_friendly: "N/A",
    sla_breach: 0,
    sla_breach_percentage_friendly: "N/A",
    excluded_from_sla: 0,
  },
  messages: {
    count: 0,
    forward: 0,
    follow_up: 0,
    initial: 0,
    replies: 0,
    received: {
      count: 0,
      forward: 0,
      follow_up: 0,
      initial: 0,
      replies: 0,
      dayOfWeek: defaultDayOfWeek(),
      hourOfDay: defaultHourOfDay(),
      avg_wait: "N/A",
      avg_wait_raw: 0,
      avg_first_wait: "N/A",
      avg_first_wait_raw: 0,
      initial_replies: 0,
    },
    sent: {
      count: 0,
      forward: 0,
      follow_up: 0,
      initial: 0,
      replies: 0,
      dayOfWeek: defaultDayOfWeek(),
      hourOfDay: defaultHourOfDay(),
      initial_replies: 0,
    },
  },
  overallTTF: {
    friendly: "N/A",
    friendly_no_business: "N/A",
    raw: null,
    raw_no_business: null,
  },
  overallTTR: {
    friendly: "N/A",
    friendly_no_business: "N/A",
    raw: null,
    raw_no_business: null,
    deviation_friendly: "N/A",
    deviation_friendly_no_business: "N/A",
    deviation_raw: null,
    deviation_raw_no_business: null,
    median_friendly: "N/A",
    median_friendly_no_business: "N/A",
    median_raw: null,
    median_raw_no_business: null,
    consistency_score: "N/A",
    consistency_score_no_business: "N/A",
    percentileRanks: [],
    percentileRanksRaw: [],
    within_sla: 0,
    within_sla_percentage_friendly: "N/A",
    sla_breach: 0,
    sla_breach_percentage_friendly: "N/A",
    excluded_from_sla: 0,
  },
  overallTTC: {
    friendly: "N/A",
    friendly_no_business: "N/A",
    raw: null,
    raw_no_business: null,
    percentileRanks: [],
    percentileRanksRaw: [],
    within_sla: 0,
    within_sla_percentage_friendly: "N/A",
    sla_breach: 0,
    sla_breach_percentage_friendly: "N/A",
  },
  threads: {
    completionRatio: {
      ratio: 0,
      numerator: 0,
      denominator: 0,
    },
    top_labels: "",
    handledRate: {
      rate: 0,
      numerator: 0,
      denominator: 0,
    },
    have_replies: 0,
    have_replies_from_agents: 0,
    have_no_replies_from_agents: 0,
    inbound: 0,
    internal: 0,
    outbound: 0,
    sent_internally: 0,
    await_customer: 0,
    await_agent: 0,
    closed: 0,
    total: 0,
    labels: {
      total: 0,
      list: [],
    },
    messages_per_conversations_avg: "N/A",
    messages_sent_per_conversations_avg: "N/A",
    messages_received_per_conversations_avg: "N/A",
  },
  type: type,
});

export type OverviewState = {
  slaMode: boolean;
  pdfMode: boolean;
  total: number;
  overviewLoading: boolean;
  layout: Record<BreakPoint, Array<FrontEndWidgetDefinition>>;
  allowedWidgets: any[];
  disableLayoutSync: boolean;
  breakPoint: BreakPoint | null;
  stats: App.DataObjects.ReportResponses.Stats.Stats;
  previousPeriod: App.DataObjects.ReportResponses.Stats.Stats;
  dailyStats: DailyStats;
  allAgentStats: LaravelLengthAwarePaginator<App.DataObjects.ReportResponses.Stats.EntityStats.EntityStat>;
  mailboxNames: MailboxNames;
  showComparisonsInLeaderboard: boolean;
  allDomainStats: LaravelLengthAwarePaginator<App.DataObjects.ReportResponses.Stats.EntityStats.EntityStat>;
  allCustomerStats: LaravelLengthAwarePaginator<App.DataObjects.ReportResponses.Stats.EntityStats.EntityStat>;
  noData: boolean;
  initialDataSet: boolean;
};

const state: OverviewState = {
  slaMode: false,
  pdfMode: false,
  total: 0,
  overviewLoading: true,
  layout: emptyLayout(),
  allowedWidgets: [],
  disableLayoutSync: false,
  breakPoint: null,
  stats: stats("stats"),
  previousPeriod: stats("previousPeriod"),
  dailyStats: {
    dates: [],
    initialTTRs: [],
    overallTTRs: [],
    overallTTFs: [],
    receivedMessages: [],
    sentMessages: [],
    repliesSent: [],
    forwardsSent: [],
    followUpsSent: [],
    totalThreads: [],
    agentRepliedThreads: [],
    agentInitialReplyThreads: [],
    noReplyThreads: [],
    replyRatios: [],
    haveCustomerSuccess: [],
    ttrRatios: [],
  },
  allAgentStats: defaultPaginator(),
  mailboxNames: {
    enabled: false,
    data: {},
  },
  showComparisonsInLeaderboard: true,
  allDomainStats: defaultPaginator(),
  allCustomerStats: defaultPaginator(),
  noData: false,
  initialDataSet: false,
};

export type OverviewMutations<S = OverviewState> = {
  setSlaMode(state: S, slaMode: boolean): void;
  setPdfMode(state: S, pdfMode: boolean): void;
  setLoading(state: S, status: boolean): void;
  setInitialDataSet(state: S, initialDataSet: boolean): void;
  setDisableLayoutSync(state: S, disableLayoutSync: boolean): void;
  clearDailyStats(state: OverviewState): void;
  setStats(state: S, stats: any): void;
  setNoData(state: S, value: boolean): void;
  setAllAgentStats(state: S, paginatorObject: LaravelLengthAwarePaginator<App.DataObjects.ReportResponses.Stats.EntityStats.EntityStat>): void;
  setMailboxNames(state: S, mailboxNames: MailboxNames): void;
  toggleMailboxNamesEnabled(state: OverviewState): void;
  setShowComparisonsInLeaderboard(state: S, newState: boolean): void;
  toggleShowComparisonsInLeaderboard(state: OverviewState): void;
  setAllDomainStats(state: S, paginatorObject: LaravelLengthAwarePaginator<App.DataObjects.ReportResponses.Stats.EntityStats.EntityStat>): void;
  setAllCustomerStats(state: S, paginatorObject: LaravelLengthAwarePaginator<App.DataObjects.ReportResponses.Stats.EntityStats.EntityStat>): void;
  clearLayout(state: OverviewState): void;
  addToLayout(state: S, {item, breakPoint}: {item: any; breakPoint: BreakPoint}): void;
  clearAllowedWidgets(state: OverviewState): void;
  pushAllowedWidget(state: S, allowedWidget: any): void;
  toggleExpandBlock(state: S, index: number): void;
  updateBlockHeight(state: S, {index, h}: {index: number; h: number}): void;
  pushToDailyStats(state: S, dailyStat: any): void;
  deleteFromLayout(state: S, index: number): void;
  moveInSetup(state: S, {oldIndex, newIndex}: {oldIndex: number; newIndex: number}): void;
};

const mutations: MutationTree<OverviewState> & OverviewMutations = {
  setSlaMode(state: OverviewState, slaMode: boolean) {
    state.slaMode = slaMode;
  },
  setPdfMode(state: OverviewState, pdfMode: boolean) {
    state.pdfMode = pdfMode;
  },
  setLoading(state: OverviewState, status: boolean) {
    state.overviewLoading = status;
  },
  setInitialDataSet(state: OverviewState, initialDataSet: boolean) {
    state.initialDataSet = initialDataSet;
  },
  setDisableLayoutSync(state: OverviewState, disableLayoutSync: boolean) {
    state.disableLayoutSync = disableLayoutSync;
  },
  clearDailyStats(state: OverviewState) {
    state.dailyStats = {
      dates: [],
      initialTTRs: [],
      overallTTRs: [],
      overallTTFs: [],
      receivedMessages: [],
      sentMessages: [],
      repliesSent: [],
      forwardsSent: [],
      followUpsSent: [],
      totalThreads: [],
      agentRepliedThreads: [],
      agentInitialReplyThreads: [],
      noReplyThreads: [],
      replyRatios: [],
      haveCustomerSuccess: [],
      ttrRatios: [],
    };
  },
  setStats(state: OverviewState, stats: any) {
    const type = stats.type as "stats" | "previousPeriod";
    state[type].initialTTR.friendly = get(stats, "initialTTR.friendly", "N/A");
    state[type].initialTTR.friendly_no_business = get(stats, "initialTTR.friendly_no_business", "N/A");
    state[type].initialTTR.raw = get(stats, "initialTTR.raw", null);
    state[type].initialTTR.raw_no_business = get(stats, "initialTTR.raw_no_business", null);
    state[type].initialTTR.deviation_friendly = get(stats, "initialTTR.deviation_friendly", "N/A");
    state[type].initialTTR.deviation_friendly_no_business = get(stats, "initialTTR.deviation_friendly_no_business", "N/A");
    state[type].initialTTR.deviation_raw = get(stats, "initialTTR.deviation_raw", null);
    state[type].initialTTR.deviation_raw_no_business = get(stats, "initialTTR.deviation_raw_no_business", null);
    state[type].initialTTR.median_friendly = get(stats, "initialTTR.median_friendly", "N/A");
    state[type].initialTTR.median_raw = get(stats, "initialTTR.median_raw", null);
    state[type].initialTTR.median_raw_no_business = get(stats, "initialTTR.median_raw_no_business", null);
    state[type].initialTTR.median_friendly_no_business = get(stats, "initialTTR.median_friendly_no_business", "N/A");
    state[type].initialTTR.consistency_score = get(stats, "initialTTR.consistency_score", "N/A");
    state[type].initialTTR.consistency_score_no_business = get(stats, "initialTTR.consistency_score_no_business", "N/A");
    state[type].initialTTR.percentileRanks = get(stats, "initialTTR.percentileRanks", []);
    state[type].initialTTR.percentileRanksRaw = get(stats, "initialTTR.percentileRanksRaw", []);
    state[type].initialTTR.within_sla = get(stats, "initialTTR.within_sla", 0);
    state[type].initialTTR.within_sla_percentage_friendly = get(stats, "initialTTR.within_sla_percentage_friendly", "N/A");
    state[type].initialTTR.sla_breach = get(stats, "initialTTR.sla_breach", 0);
    state[type].initialTTR.sla_breach_percentage_friendly = get(stats, "initialTTR.sla_breach_percentage_friendly", "N/A");
    state[type].initialTTR.excluded_from_sla = get(stats, "initialTTR.excluded_from_sla", 0);

    state[type].overallTTR.friendly = get(stats, "overallTTR.friendly", "N/A");
    state[type].overallTTR.friendly_no_business = get(stats, "overallTTR.friendly_no_business", "N/A");
    state[type].overallTTR.raw = get(stats, "overallTTR.raw", null);
    state[type].overallTTR.raw_no_business = get(stats, "overallTTR.raw_no_business", null);
    state[type].overallTTR.deviation_friendly = get(stats, "overallTTR.deviation_friendly", "N/A");
    state[type].overallTTR.deviation_friendly_no_business = get(stats, "overallTTR.deviation_friendly_no_business", "N/A");
    state[type].overallTTR.deviation_raw = get(stats, "overallTTR.deviation_raw", null);
    state[type].overallTTR.deviation_raw_no_business = get(stats, "overallTTR.deviation_raw_no_business", null);
    state[type].overallTTR.median_friendly = get(stats, "overallTTR.median_friendly", "N/A");
    state[type].overallTTR.median_friendly_no_business = get(stats, "overallTTR.median_friendly_no_business", "N/A");
    state[type].overallTTR.median_raw = get(stats, "overallTTR.median_raw", null);
    state[type].overallTTR.median_raw_no_business = get(stats, "overallTTR.median_raw_no_business", null);
    state[type].overallTTR.consistency_score = get(stats, "overallTTR.consistency_score", "N/A");
    state[type].overallTTR.consistency_score_no_business = get(stats, "overallTTR.consistency_score_no_business", "N/A");
    state[type].overallTTR.percentileRanks = get(stats, "overallTTR.percentileRanks", []);
    state[type].overallTTR.percentileRanksRaw = get(stats, "overallTTR.percentileRanksRaw", []);
    state[type].overallTTR.within_sla = get(stats, "overallTTR.within_sla", 0);
    state[type].overallTTR.within_sla_percentage_friendly = get(stats, "overallTTR.within_sla_percentage_friendly", "N/A");
    state[type].overallTTR.sla_breach = get(stats, "overallTTR.sla_breach", 0);
    state[type].overallTTR.sla_breach_percentage_friendly = get(stats, "overallTTR.sla_breach_percentage_friendly", "N/A");
    state[type].overallTTR.excluded_from_sla = get(stats, "overallTTR.excluded_from_sla", 0);

    state[type].overallTTF.friendly = get(stats, "overallTTF.friendly", "N/A");
    state[type].overallTTF.friendly_no_business = get(stats, "overallTTF.friendly_no_business", "N/A");
    state[type].overallTTF.raw = get(stats, "overallTTF.raw", null);
    state[type].overallTTF.raw_no_business = get(stats, "overallTTF.raw_no_business", null);

    state[type].overallTTC.friendly = get(stats, "overallTTC.friendly", "N/A");
    state[type].overallTTC.friendly_no_business = get(stats, "overallTTC.friendly_no_business", "N/A");
    state[type].overallTTC.raw = get(stats, "overallTTC.raw", null);
    state[type].overallTTC.raw_no_business = get(stats, "overallTTC.raw_no_business", null);
    state[type].overallTTC.percentileRanks = get(stats, "overallTTC.percentileRanks", []);
    state[type].overallTTC.percentileRanksRaw = get(stats, "overallTTC.percentileRanksRaw", []);
    state[type].overallTTC.within_sla = get(stats, "overallTTC.within_sla", 0);
    state[type].overallTTC.within_sla_percentage_friendly = get(stats, "overallTTC.within_sla_percentage_friendly", "N/A");
    state[type].overallTTC.sla_breach = get(stats, "overallTTC.sla_breach", 0);
    state[type].overallTTC.sla_breach_percentage_friendly = get(stats, "overallTTC.sla_breach_percentage_friendly", "N/A");
    state[type].threads.handledRate.rate = get(stats, "threads.handledRate.rate", 0);
    state[type].threads.handledRate.numerator = get(stats, "threads.handledRate.numerator", 0);
    state[type].threads.handledRate.denominator = get(stats, "threads.handledRate.denominator", 0);
    state[type].threads.have_replies = get(stats, "threads.have_replies", 0);
    state[type].threads.have_replies_from_agents = get(stats, "threads.have_replies_from_agents", 0);
    state[type].threads.have_no_replies_from_agents = get(stats, "threads.have_no_replies_from_agents", 0);
    state[type].threads.inbound = get(stats, "threads.inbound", 0);
    state[type].threads.internal = get(stats, "threads.internal", 0);
    state[type].threads.outbound = get(stats, "threads.outbound", 0);
    state[type].threads.sent_internally = get(stats, "threads.sent_internally", 0);
    state[type].threads.await_customer = get(stats, "threads.await_customer", 0);
    state[type].threads.await_agent = get(stats, "threads.await_agent", 0);
    state[type].threads.closed = get(stats, "threads.closed", 0);
    state[type].threads.total = get(stats, "threads.total", 0);
    state[type].threads.labels = get(stats, "threads.labels", []);
    state[type].threads.messages_per_conversations_avg = get(stats, "threads.messages_per_conversations_avg", "N/A");
    state[type].threads.messages_sent_per_conversations_avg = get(stats, "threads.messages_sent_per_conversations_avg", "N/A");
    state[type].threads.messages_received_per_conversations_avg = get(stats, "threads.messages_received_per_conversations_avg", "N/A");

    state[type].messages.count = get(stats, "messages.count", 0);
    state[type].messages.forward = get(stats, "messages.forward", 0);
    state[type].messages.follow_up = get(stats, "messages.follow_up", 0);
    state[type].messages.initial = get(stats, "messages.initial", 0);
    state[type].messages.replies = get(stats, "messages.replies", 0);

    state[type].messages.received.count = get(stats, "messages.received.count", 0);
    state[type].messages.received.forward = get(stats, "messages.received.forward", 0);
    state[type].messages.received.follow_up = get(stats, "messages.received.follow_up", 0);
    state[type].messages.received.initial = get(stats, "messages.received.initial", 0);
    state[type].messages.received.replies = get(stats, "messages.received.replies", 0);
    state[type].messages.received.avg_wait = get(stats, "messages.received.avg_wait", "N/A");
    state[type].messages.received.avg_wait_raw = get(stats, "messages.received.avg_wait_raw", 0);
    state[type].messages.received.avg_first_wait = get(stats, "messages.received.avg_first_wait", "N/A");
    state[type].messages.received.avg_first_wait_raw = get(stats, "messages.received.avg_first_wait_raw", 0);

    state[type].messages.sent.count = get(stats, "messages.sent.count", 0);
    state[type].messages.sent.forward = get(stats, "messages.sent.forward", 0);
    state[type].messages.sent.follow_up = get(stats, "messages.sent.follow_up", 0);
    state[type].messages.sent.initial = get(stats, "messages.sent.initial", 0);
    state[type].messages.sent.replies = get(stats, "messages.sent.replies", 0);

    ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].forEach((day) => {
      state[type].messages.received.dayOfWeek[day as keyof App.DataObjects.ReportResponses.Stats.DayOfWeek] = get(stats, "messages.received.dayOfWeek." + day, 0);
      state[type].messages.sent.dayOfWeek[day as keyof App.DataObjects.ReportResponses.Stats.DayOfWeek] = get(stats, "messages.sent.dayOfWeek." + day, 0);
    });

    for (let i = 0; i < 24; i++) {
      const hour = i < 10 ? "0" + i + ":00" : i + ":00";
      state[type].messages.received.hourOfDay[hour as keyof App.DataObjects.ReportResponses.Stats.HourOfDay] = get(stats, "messages.received.hourOfDay[" + hour + "]", 0);
      state[type].messages.sent.hourOfDay[hour as keyof App.DataObjects.ReportResponses.Stats.HourOfDay] = get(stats, "messages.sent.hourOfDay[" + hour + "]", 0);
    }
  },

  setNoData(state: OverviewState, value: boolean) {
    state.noData = value;
  },
  setAllAgentStats(state: OverviewState, paginatorObject: LaravelLengthAwarePaginator<App.DataObjects.ReportResponses.Stats.EntityStats.EntityStat>) {
    state.allAgentStats.current_page = get(paginatorObject, "current_page", 1);
    state.allAgentStats.data = [];
    paginatorObject.data.forEach((i: any) => state.allAgentStats.data.push(i));
    state.allAgentStats.last_page = get(paginatorObject, "last_page", 1);
    state.allAgentStats.per_page = get(paginatorObject, "per_page", 15);
    state.allAgentStats.total = get(paginatorObject, "total", 0);
  },
  setMailboxNames(state: OverviewState, mailboxNames: MailboxNames) {
    state.mailboxNames.enabled = get(mailboxNames, "enabled", false);
    for (const [key, value] of Object.entries(get(mailboxNames, "data", {}))) {
      Vue.set(state.mailboxNames.data, key, value);
    }
  },
  toggleMailboxNamesEnabled(state: OverviewState) {
    state.mailboxNames.enabled = !state.mailboxNames.enabled;
  },
  setShowComparisonsInLeaderboard(state: OverviewState, newState: boolean) {
    state.showComparisonsInLeaderboard = newState;
  },
  toggleShowComparisonsInLeaderboard(state: OverviewState) {
    state.showComparisonsInLeaderboard = !state.showComparisonsInLeaderboard;
  },

  setAllDomainStats(state: OverviewState, paginatorObject: LaravelLengthAwarePaginator<App.DataObjects.ReportResponses.Stats.EntityStats.EntityStat>) {
    state.allDomainStats.current_page = get(paginatorObject, "current_page", 1);
    state.allDomainStats.data = [];
    paginatorObject.data.forEach((i: any) => state.allDomainStats.data.push(i));
    state.allDomainStats.last_page = get(paginatorObject, "last_page", 1);
    state.allDomainStats.per_page = get(paginatorObject, "per_page", 15);
    state.allDomainStats.total = get(paginatorObject, "total", 0);
  },

  setAllCustomerStats(state: OverviewState, paginatorObject: LaravelLengthAwarePaginator<App.DataObjects.ReportResponses.Stats.EntityStats.EntityStat>) {
    state.allCustomerStats.current_page = get(paginatorObject, "current_page", 1);
    state.allCustomerStats.data = [];
    paginatorObject.data.forEach((i: any) => state.allCustomerStats.data.push(i));
    state.allCustomerStats.last_page = get(paginatorObject, "last_page", 1);
    state.allCustomerStats.per_page = get(paginatorObject, "per_page", 15);
    state.allCustomerStats.total = get(paginatorObject, "total", 0);
  },
  clearLayout(state: OverviewState) {
    const breakPoints = breakPointsDetails();
    if (state.breakPoint === null) {
      return;
    }
    oldLayout[state.breakPoint as keyof typeof breakPoints] = [];
    state.layout[state.breakPoint as keyof typeof breakPoints] = [];
  },

  addToLayout(state: OverviewState, {item, breakPoint}: {item: any; breakPoint: BreakPoint}) {
    if (typeof item === "undefined") {
      return;
    }
    if (!state.layout[breakPoint]) {
      return;
    }
    //check for others of same type
    if (state.layout[breakPoint].some((i: any) => i.i === item.type)) {
      return;
    }

    //also check if we are in pdf mode, do not add "add-mailboxes"
    if (state.pdfMode && item.type === "add-mailboxes") {
      return;
    }

    let x = item.x;
    let y = item.y;
    const bpCols = (breakPointsDetails()[breakPoint] as BreakPointType).cols;

    //check for collisions with previous, or set X/Y is undefined
    const previousItem = state.layout[breakPoint][state.layout[breakPoint].length - 1];
    if (typeof item.x === "undefined" || typeof item.y === "undefined") {
      if (typeof previousItem === "undefined") {
        x = 0;
        y = 0;
      } else {
        [x, y] = calculatePosition(previousItem, item, bpCols);
      }
    } else if (typeof previousItem !== "undefined") {
      if (doItemsOverlap(previousItem, item, bpCols)) {
        [x, y] = calculatePosition(previousItem, item, bpCols);
      }
    }

    const newItem = {
      x: x,
      y: y,
      w: Math.min(item.w, bpCols), //do not let item be wider than cols
      h: 1,
      i: item.type,
      options: item.options,
      name: item.name,
      static: item.static,
      expanded: item.expanded,
      moved: false,
      beta: item.beta,
      deletable: !!item.deletable,
    } as FrontEndWidgetDefinition;

    state.layout[breakPoint].push(newItem);
  },

  clearAllowedWidgets(state: OverviewState) {
    state.allowedWidgets = [];
  },

  pushAllowedWidget(state: OverviewState, allowedWidget: any) {
    state.allowedWidgets.push(allowedWidget);
  },

  toggleExpandBlock(state: OverviewState, index: number) {
    if (state.breakPoint === null) {
      return;
    }
    //we need to replace the original item with a new one for watcher to work.
    const clone = cloneDeep(state.layout[state.breakPoint][index]);
    clone.expanded = !state.layout[state.breakPoint][index].expanded;
    state.layout[state.breakPoint].splice(index, 1, clone);
  },

  updateBlockHeight(state: OverviewState, {index, h}: {index: number; h: number}) {
    if (state.breakPoint === null) {
      return;
    }
    //ensure item index actually exists
    if (state.layout[state.breakPoint][index]) {
      const clone = cloneDeep(state.layout[state.breakPoint][index]);
      clone.h = h;
      state.layout[state.breakPoint].splice(index, 1, clone);
    }
  },

  pushToDailyStats(state: OverviewState, dailyStat: any) {
    state.dailyStats.dates.push(dailyStat.date);
    state.dailyStats.initialTTRs.push(dailyStat.initialTTR.raw);
    state.dailyStats.overallTTRs.push(dailyStat.overallTTR.raw);
    state.dailyStats.overallTTFs.push(dailyStat.overallTTF.raw);
    state.dailyStats.receivedMessages.push(dailyStat.messages.received);
    state.dailyStats.sentMessages.push(dailyStat.messages.sent);
    state.dailyStats.repliesSent.push(get(dailyStat.messages, "reply", 0));
    state.dailyStats.forwardsSent.push(get(dailyStat.messages, "forward", 0));
    state.dailyStats.followUpsSent.push(get(dailyStat.messages, "follow_up", 0));
    state.dailyStats.totalThreads.push(dailyStat.threads.total);
    state.dailyStats.agentRepliedThreads.push(dailyStat.threads.have_replies_from_agents);
    state.dailyStats.noReplyThreads.push(dailyStat.threads.have_no_replies_from_agents);
    state.dailyStats.replyRatios.push(dailyStat.threads.completionRatio);
    state.dailyStats.haveCustomerSuccess.push(get(dailyStat.threads, "haveCustomerSuccess", 0));
    state.dailyStats.agentInitialReplyThreads.push(get(dailyStat.threads, "have_initial_replies_from_agents", 0));
  },
  deleteFromLayout(state: OverviewState, index: number) {
    if (state.breakPoint === null) {
      return;
    }
    if (state.layout[state.breakPoint][index]) {
      const module = state.layout[state.breakPoint][index].name;
      const reportName = state.slaMode ? "slaReportModuleChanged" : "overviewReportModuleChanged";
      trackEvent(reportName, {module: module, action: "deactivate"} as any);
      state.layout[state.breakPoint].splice(index, 1);

      //now delete from all layouts
      for (const key in Object.keys(breakPointsDetails())) {
        //first find the index
        const moduleIndex = findIndex(state.layout[key as BreakPoint], (item: any) => item.name === module);
        if (moduleIndex >= 0) {
          state.layout[key as BreakPoint].splice(moduleIndex, 1);
        }
      }
    }
  },

  moveInSetup(state: OverviewState, {oldIndex, newIndex}: {oldIndex: number; newIndex: number}) {
    if (state.breakPoint === null) {
      return;
    }
    //we also need to change the x, y coordinates.
    const movedItem = {...state.layout[state.breakPoint][oldIndex]};
    const swappedItem = {...state.layout[state.breakPoint][newIndex]};

    movedItem.x = state.layout[state.breakPoint][newIndex].x;
    movedItem.y = state.layout[state.breakPoint][newIndex].y;

    swappedItem.x = state.layout[state.breakPoint][oldIndex].x;
    swappedItem.y = state.layout[state.breakPoint][oldIndex].y;

    //plop in the new elements
    state.layout[state.breakPoint].splice(newIndex, 1, movedItem);
    state.layout[state.breakPoint].splice(oldIndex, 1, swappedItem);
  },
};

export type OverviewGetters = {
  requestParams: (state: OverviewState, getters: OverviewGetters, rootState: RootState, rootGetters: RootGetters) => SearchParams;
  currentLayout: (state: OverviewState, getters: OverviewGetters, rootState: RootState, rootGetters: RootGetters) => FrontEndWidgetDefinition[];
};

const getters: GetterTree<OverviewState, RootState> & OverviewGetters = {
  requestParams: (state: OverviewState, getters: OverviewGetters, rootState: RootState, rootGetters: RootGetters) => {
    const params: SearchParams = {...rootGetters["threadFilters/searchParams"]};
    if (state.slaMode) {
      params.sort_by = rootState.threadFilters.sla.sortBy;
      params.direction = rootState.threadFilters.sla.direction;
      params.per_page_agents = rootState.threadFilters.sla.perPageAgents;
      params.page_agents = rootState.threadFilters.sla.pageAgents;
      params.per_page_contacts = rootState.threadFilters.sla.perPageContacts;
      params.page_contacts = rootState.threadFilters.sla.pageContacts;
      params.per_page_domains = rootState.threadFilters.sla.perPageDomains;
      params.page_domains = rootState.threadFilters.sla.pageDomains;
      params.init_ttr_goal = rootState.threadFilters.sla.init_ttr_goal;
      params.overall_ttr_goal = rootState.threadFilters.sla.overall_ttr_goal;
      params.overall_ttc_goal = rootState.threadFilters.sla.overall_ttc_goal;
    } else {
      params.sort_by = rootState.threadFilters.overview.sortBy;
      params.direction = rootState.threadFilters.overview.direction;
      params.per_page_agents = rootState.threadFilters.overview.perPageAgents;
      params.page_agents = rootState.threadFilters.overview.pageAgents;
      params.per_page_contacts = rootState.threadFilters.overview.perPageContacts;
      params.page_contacts = rootState.threadFilters.overview.pageContacts;
      params.per_page_domains = rootState.threadFilters.overview.perPageDomains;
      params.page_domains = rootState.threadFilters.overview.pageDomains;
    }
    return params;
  },
  currentLayout: (state: OverviewState) => {
    if (state.breakPoint === null) {
      return [];
    }
    const noCustomerStats = state.allCustomerStats.total < 1;
    const noDomainStats = state.allDomainStats.total < 1;
    //for domains and contacts leaderboard, if they have no data, then don't show them at all.
    return state.layout[state.breakPoint].filter((item: any) => {
      return !((noCustomerStats && item.i === "customer-stats-table") || (noDomainStats && item.i === "domain-stats-table"));
    });
  },
};

export type OverviewActions<S = OverviewState, R = RootState> = {
  loadAsyncData(context: ActionContext<S, R>): void;
  setData(context: ActionContext<S, R>, data: any): void;
  setAllowedWidgets(context: ActionContext<S, R>, allowedWidgets: any[]): void;
  updateLayoutFromSetup(context: ActionContext<S, R>, {setup, clear}: {setup: any; clear: boolean}): void;
  setLayoutOptionVisible(context: ActionContext<S, R>, {index, columnIndex, visible}: {index: number; columnIndex: number; visible: boolean}): void;
  resetOverviewToDefault(context: ActionContext<S, R>): void;
  addToLayoutAndSync(context: ActionContext<S, R>, item: any): void;
  breakPointChanged(context: ActionContext<S, R>, breakPoint: BreakPoint): void;
  syncLayout(context: ActionContext<S, R>, newLayout: any): void;
  moveIndexDown(context: ActionContext<S, R>, index: number): void;
  moveIndexUp(context: ActionContext<S, R>, index: number): void;
  deleteIndexBox(context: ActionContext<S, R>, index: number): void;
  toggleShowMailboxNamesPreference(context: ActionContext<S, R>): void;
  toggleShowComparisonsInLeaderboardPreference(context: ActionContext<S, R>): void;
};

const actions: ActionTree<OverviewState, RootState> & OverviewActions = {
  loadAsyncData({dispatch, state, commit, getters}: ActionContext<OverviewState, RootState>) {
    if (state.pdfMode) {
      return;
    }
    commit("setLoading", true);
    const params = {...getters.requestParams};

    if (cancelController) {
      cancelController.abort();
    }

    cancelController = new AbortController();

    if (state.slaMode) {
      apiReportsSlaIndex(params, {
        signal: cancelController.signal,
      })
        .then((response) => {
          const data = response.data;
          data.mode = state.slaMode;
          dispatch("setData", data);
        })
        .catch((error: AxiosError<LaravelValidationErrors | TtrAnErrorResponse>) => {
          if (error.code === "ERR_CANCELED") {
            return;
          }
          commit("setLoading", false);

          dispatch(
            "user/recordErrors",
            {
              data: getErrorMessageFromValidationError(error, "Something went wrong loading your data. Try making the date range smaller. If the problem persists, please contact us."),
              type: "toast",
            },
            {root: true},
          );
        })
        .finally(() => (cancelController = undefined));
    } else {
      apiReportsOverviewIndex(params, {
        signal: cancelController.signal,
      })
        .then((response) => {
          const data = response.data;
          data.mode = state.slaMode;
          dispatch("setData", data);
        })
        .catch((error: AxiosError<LaravelValidationErrors | TtrAnErrorResponse>) => {
          if (error.code === "ERR_CANCELED") {
            return;
          }
          commit("setLoading", false);

          dispatch(
            "user/recordErrors",
            {
              data: getErrorMessageFromValidationError(error, "Something went wrong loading your data. Try making the date range smaller. If the problem persists, please contact us."),
              type: "toast",
            },
            {root: true},
          );
        })
        .finally(() => (cancelController = undefined));
    }
  },
  setData({state, dispatch, commit, rootState}: ActionContext<OverviewState, RootState>, data: App.DataObjects.ReportResponses.OverviewSlaReportResponseData) {
    commit("setAllAgentStats", data.all_agent_stats);
    commit("setMailboxNames", data.mailbox_names);
    commit("setShowComparisonsInLeaderboard", data.show_comparisons_in_leaderboard);
    commit("setAllDomainStats", data.all_domain_stats);
    commit("setAllCustomerStats", data.all_customer_stats);

    const paginationData = {
      pageAgents: Number(data.all_agent_stats.current_page),
      perPageAgents: Number(data.all_agent_stats.per_page),
      pageDomains: Number(data.all_domain_stats.current_page),
      perPageDomains: Number(data.all_domain_stats.per_page),
      pageContacts: Number(data.all_customer_stats.current_page),
      perPageContacts: Number(data.all_customer_stats.per_page),
      init_ttr_goal: data.args.init_ttr_goal,
      overall_ttr_goal: data.args.overall_ttr_goal,
      overall_ttc_goal: data.args.overall_ttc_goal,
    };

    commit("threadFilters/setOverview", paginationData, {root: true});

    commit("threadFilters/setSla", paginationData, {root: true});

    data.stats.type = "stats";
    commit("setStats", data.stats);
    if (data.stats.threads.total === 0 && data.stats.messages.count === 0) {
      commit("setNoData", true);
    } else {
      commit("setNoData", false);
    }

    if (state.slaMode && data.previous_period) {
      data.previous_period.type = "previousPeriod";
      commit("setStats", data.previous_period);
    }

    commit("clearDailyStats");
    let dailyStats = data.stats.dailyStats;

    dailyStats = sortBy(dailyStats, [
      function (stat: any) {
        return stat.timestamp;
      },
    ]);

    dailyStats.forEach((dailyStat: any) => commit("pushToDailyStats", dailyStat));

    dispatch("updateLayoutFromSetup", {setup: data.setup, clear: false});

    commit("threadFilters/setModelSelectedFull", get(data, "args.model", myCompany()), {root: true});

    const productType = rootState.threadFilters.productType;

    commit("threadFilters/setModelSelectedComFull", get(data, "args.modelCom", productType === "success" ? anybody() : allLeads()), {root: true});

    commit("setLoading", false);

    commit("setInitialDataSet", true);
  },
  setAllowedWidgets({commit}: ActionContext<OverviewState, RootState>, allowedWidgets: any[]) {
    commit("clearAllowedWidgets");
    allowedWidgets.forEach((allowedWidget: any) => commit("pushAllowedWidget", allowedWidget));
  },
  updateLayoutFromSetup({state, commit, dispatch}: ActionContext<OverviewState, RootState>, {setup, clear}: {setup: any; clear: boolean}) {
    if (state.disableLayoutSync) {
      return;
    }
    if (clear) {
      oldLayout = emptyLayout();
      state.layout = emptyLayout();
    }

    const domainsWidget = find(state.allowedWidgets, function (loadedOption: any) {
      return loadedOption.type === "domain-stats-table";
    });
    const contactsWidget = find(state.allowedWidgets, function (loadedOption: any) {
      return loadedOption.type === "customer-stats-table";
    });

    if (setup == null) {
      dispatch("resetOverviewToDefault");
    } else {
      for (const breakPoint in setup) {
        if (Array.isArray(setup[breakPoint])) {
          setup[breakPoint].forEach((item: any) => commit("addToLayout", {item: item, breakPoint: breakPoint}));

          //if domain stats not empty, we should add domains leaderboard to widgets
          if (state.allDomainStats.total > 0) {
            commit("addToLayout", {
              item: domainsWidget,
              breakPoint: breakPoint,
            });
          }

          //if customer stats not empty, we should add contacts leaderboard to widgets
          if (state.allCustomerStats.total > 0) {
            commit("addToLayout", {
              item: contactsWidget,
              breakPoint: breakPoint,
            });
          }
        }
      }
    }

    oldLayout = cloneDeep(state.layout);
  },
  setLayoutOptionVisible({state, dispatch}: ActionContext<OverviewState, RootState>, {index, columnIndex, visible}: {index: number; columnIndex: number; visible: boolean}) {
    if (state.breakPoint === null) {
      return;
    }

    const layoutForBreakPoint = state.layout[state.breakPoint];
    const targetColumn = layoutForBreakPoint?.[index]?.options?.columns?.[columnIndex];

    if (targetColumn) {
      targetColumn.visible = visible;
      dispatch("syncLayout", layoutForBreakPoint);
    }
  },
  resetOverviewToDefault({commit}: ActionContext<OverviewState, RootState>) {
    if (state.pdfMode) {
      return;
    }
    commit("clearLayout");

    const type = state.slaMode ? "sla_setup" : "overview_setup";
    apiSetupUpdate(undefined, {data: [], type: type, breakPoint: state.breakPoint}).then((response) => {
      if (Array.isArray(response.data)) {
        return;
      }
      if (state.breakPoint !== null) {
        response.data[state.breakPoint].forEach((i: any) => commit("addToLayout", {item: i, breakPoint: state.breakPoint}));
      }
      oldLayout = cloneDeep(state.layout);
    });
  },
  addToLayoutAndSync({commit, state}: ActionContext<OverviewState, RootState>, item: any) {
    if (state.breakPoint === null) {
      return;
    }
    // track event
    const reportName = state.slaMode ? "slaReportModuleChanged" : "overviewReportModuleChanged";
    trackEvent(reportName, {module: item.name, action: "activate"} as any);

    for (const key in breakPointsDetails()) {
      commit("addToLayout", {item: item, breakPoint: key});
    }
  },
  breakPointChanged({state}: ActionContext<OverviewState, RootState>, breakPoint: BreakPoint) {
    state.breakPoint = breakPoint;
  },
  syncLayout({state, dispatch, commit}: ActionContext<OverviewState, RootState>, newLayout: any) {
    if (state.breakPoint === null || state.pdfMode || state.disableLayoutSync) {
      return;
    }
    if (isEqual(oldLayout[state.breakPoint], newLayout)) {
      return;
    }
    if (newLayout.length === 0) {
      return;
    }

    const type = state.slaMode ? "sla_setup" : "overview_setup";
    const name = state.slaMode ? "SLA" : "Overview";

    let mapped: FrontEndWidgetDefinition[] = [];
    if (Array.isArray(newLayout)) {
      mapped = newLayout.map((item: any) => {
        return {
          x: item.x,
          y: item.y,
          w: item.w,
          h: item.h,
          type: item.i,
          options: item.options,
          name: item.name,
          static: item.static,
          expanded: item.expanded,
        } as FrontEndWidgetDefinition;
      });
    }

    apiSetupUpdate(undefined, {data: mapped, type: type, breakPoint: state.breakPoint})
      .then((response) => {
        if (Array.isArray(response.data)) {
          return;
        }
        oldLayout = cloneDeep(state.layout);
        if (mapped.length < 1 && state.breakPoint !== null) {
          response.data[state.breakPoint].forEach((i: any) => commit("addToLayout", {item: i, breakPoint: state.breakPoint}));
        }
        for (const [breakpoint, value] of Object.entries(response.data)) {
          if (breakpoint !== state.breakPoint && Array.isArray(value) && ["2xl", "xl", "lg", "md", "sm"].includes(breakpoint)) {
            state.layout[breakpoint as BreakPoint] = [];
            value.forEach((i: any) => commit("addToLayout", {item: i, breakPoint: breakpoint}));
          }
        }
      })
      .catch(() => {
        dispatch(
          "user/recordErrors",
          {
            data: "There was a problem saving your " + name + " layout.",
            type: "toast",
          },
          {root: true},
        );
      });
  },
  moveIndexDown({dispatch, state, commit}: ActionContext<OverviewState, RootState>, index: number) {
    if (state.breakPoint === null) {
      return;
    }
    const newIndex = parseInt(index.toString()) + 1;
    if (newIndex >= state.layout[state.breakPoint].length) {
      return;
    }
    commit("moveInSetup", {oldIndex: index, newIndex: newIndex});
    dispatch("syncLayout", state.layout[state.breakPoint]);
  },
  moveIndexUp({dispatch, commit}: ActionContext<OverviewState, RootState>, index: number) {
    if (state.breakPoint === null) {
      return;
    }
    const newIndex = parseInt(index.toString()) - 1;
    if (newIndex < 0) {
      return;
    }
    commit("moveInSetup", {oldIndex: index, newIndex: newIndex});
    dispatch("syncLayout", state.layout[state.breakPoint]);
  },
  deleteIndexBox({commit}: ActionContext<OverviewState, RootState>, index: number) {
    commit("deleteFromLayout", index);
  },
  toggleShowMailboxNamesPreference({commit, state, dispatch}: ActionContext<OverviewState, RootState>) {
    if (state.pdfMode) {
      return;
    }
    commit("setLoading", true);
    const isEnabled = !state.mailboxNames.enabled;
    commit("toggleMailboxNamesEnabled");

    apiCompanyPreferenceUpdate(undefined, {
      preference: "display_agent_name_in_leaderboard",
      preference_value: isEnabled,
    })
      .catch((error: AxiosError<LaravelValidationErrors | TtrAnErrorResponse>) => {
        dispatch(
          "user/recordErrors",
          {
            data: getErrorMessageFromValidationError(error, "Something went wrong, please try again"),
            type: "toast",
          },
          {root: true},
        );
      })
      .finally(() => commit("setLoading", false));
  },
  toggleShowComparisonsInLeaderboardPreference({commit, state, dispatch}: ActionContext<OverviewState, RootState>) {
    if (state.pdfMode) {
      return;
    }
    commit("setLoading", true);
    const newState = !state.showComparisonsInLeaderboard;
    commit("toggleShowComparisonsInLeaderboard");

    apiCompanyPreferenceUpdate({
      preference: "show_comparisons_in_leaderboard",
      preference_value: newState,
    })
      .catch((error: AxiosError<LaravelValidationErrors | TtrAnErrorResponse>) => {
        dispatch(
          "user/recordErrors",
          {
            data: getErrorMessageFromValidationError(error, "Something went wrong, please try again"),
            type: "toast",
          },
          {root: true},
        );
      })
      .finally(() => commit("setLoading", false));
  },
};

const overview: Module<OverviewState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default overview;
